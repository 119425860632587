import React from 'react';
import '../../css/StaticContent.scss';
import { AppBar } from '@material-ui/core';
import Toolbar from '@material-ui/core/Toolbar';
import Link from '@material-ui/core/Link';
import maraLogo from '../../assets/logo-MARA.svg';
import logo from '../../assets/logo.svg';

interface NavigationBarProps {
  isAffiliate: boolean;
}

export const NavigationBar: React.FC<NavigationBarProps> = (props: NavigationBarProps) => {
  const { isAffiliate } = props;
  return (
    <AppBar position="static" className="mara-navbar">
      <Toolbar className="navbar-brand">
        <Link href={isAffiliate ? './affiliate' : './'}>
          <img src={maraLogo} className="mara-logo" alt="mara-logo" />
        </Link>
      </Toolbar>
      <Toolbar className="navbar-group">
        <Link href={isAffiliate ? './affiliateBenefits' : './benefits'} className="navbar-link">
          Benefits
        </Link>
        <Link href={isAffiliate ? './affiliateResources' : './resources'} className="navbar-link">
          Resources
        </Link>
        {!isAffiliate && (
          <Link href="./demoRegister" className="navbar-link">
            Demo
          </Link>
        )}
        <Link href={isAffiliate ? './affiliateRegister' : './register'} className="navbar-registration-link">
          {isAffiliate ? 'Activate Registration' : 'Participation Options'}
        </Link>

        <Link href="./training" className="navbar-training-link">
          <img src={logo} className="logo" alt="mara-logo" />ACAMS Training
        </Link>
      </Toolbar>

    </AppBar>
  );
};

import React, { useReducer } from 'react';
import Reducer from './reducer';
import { ActionType, ContextGroup, StateType } from '../../constants/context';

const initialState: StateType = {
  [ContextGroup.REGISTRATION]: {},
  [ContextGroup.DEMO_REGISTRATION]: {},
  [ContextGroup.CSV_UPLOAD]: {},
  [ContextGroup.PASSWORD_SET]: {}
};

export const Context = React.createContext<{
  state: StateType;
  dispatch: (action: ActionType) => void;
}>({
  state: initialState,
  //eslint-disable-next-line @typescript-eslint/no-empty-function
  dispatch: () => {}
});

const Store = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return <Context.Provider value={{ state, dispatch }}>{children}</Context.Provider>;
};

export default Store;

import React, { useEffect } from 'react';
import { NavigationBar } from './NavigationBar';
import Typography from '@material-ui/core/Typography';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';
import { ExpansionPanel } from '@material-ui/core';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import pdf from '../../assets/acams-brochure.pdf';
import { Footer } from './Footer';
import brochureLogo from '../../assets/download-brochure.png';
import resourceOne from '../../assets/resource-1.jpg';
import resourceTwo from '../../assets/resource-2.jpg';
import resourceThree from '../../assets/resource-3.jpg';
import resourceFour from '../../assets/resource-4.png';
import resourceFive from '../../assets/resource-5.png';
import resourceSix from '../../assets/resource-6.jpg';
import resourceSeven from '../../assets/resource-7.jpg';
import resourceEight from '../../assets/resource-8.png';
import resourceNine from '../../assets/resource-9.jpg';
import resourceTen from '../../assets/resource-10.png';
import resourceEleven from '../../assets/resource-11.png';
import resourceTwelve from '../../assets/resource-12.jpg';
import pdfOne from '../../assets/1027.pdf';
import pdfTwo from '../../assets/acams-indepth.pdf';
import pdfThree from '../../assets/exploring-an-industry.pdf';
import pdfFour from '../../assets/FATF-guidance-banking.pdf';
import pdfFive from '../../assets/Wolfsberg.pdf';
import pdfSix from '../../assets/mltf-risk-assessment.pdf';
import pdfSeven from '../../assets/FATF-Digital-Identity.pdf';
import pdfEight from '../../assets/Basel-Essential-Elements.pdf';
import pdfNine from '../../assets/fatf-guidance.pdf';
import pdfTen from '../../assets/Deep-dive.pdf';
import pdfEleven from '../../assets/Basal-Committee.pdf';
import pdfTwelve from '../../assets/risk-based-principle.pdf';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

interface ResourcesProps {
  isAffiliate: boolean;
  path: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`
  };
}

export const Resources: React.FC<ResourcesProps> = (props: ResourcesProps) => {
  const { isAffiliate } = props;
  const [value, setValue] = React.useState(0);
  useEffect(() => {
    document.title = 'Resources: Brochure, FAQs, Articles | Mastercard ACAMS Risk Assessment';
  }, []);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <React.Fragment>
      <NavigationBar isAffiliate={isAffiliate}></NavigationBar>
      <div className="brochures-banner">
        <div className="navtabs-box">
          <AppBar position="static" className="resource-tabs" elevation={0}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="simple tabs example"
              indicatorColor="#002368"
              centered
            >
              <Tab label="Brochures & FAQs" {...a11yProps(0)} disableRipple={true} />
              <Tab label="Learning" {...a11yProps(1)} disableRipple={true} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <Container fixed>
              <div className="brochures-pdf-link">
                <Link href={pdf}>
                  <img src={brochureLogo} className="pdf-icon" alt="pdf-icon"></img>
                </Link>
              </div>
            </Container>

            <div className="faq-accordion-box">
              <Typography className="faq-subtitle" variant="h6" gutterBottom={true} display="block">
                Frequently Asked Questions
              </Typography>
              <Typography className="faq-section-subtitle" variant="h6" gutterBottom={true} display="block">
                General FAQs
              </Typography>
              <div className="faq-accordion">
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Why is Mastercard offering this product, what are the benefits for customers? OR how will my
                      institution benefit from the tool?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Over the years, the number of enforcement actions and formal regulatory criticisms has risen
                      dramatically for financial institutions. The common denominator cited in these cases is inadequate
                      risk mitigation, highlighting the need to improve risk assessment processes, methods, and
                      procedures. To meet this regulatory concern, Mastercard ACAMS Risk Assessment objectively and
                      consistently responds to the guidance and regulatory requirements of financial institution
                      supervisors and regulators world-wide as they pertain to Mastercard products
                      (including virtual assets/crypto products and services an organization’s
                      “high risk” customers, and geographies in which they do business.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      What/Who is ACAMS?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      The third-party vendor, ACAMS (The Association of Certified Anti-Money Laundering Specialists), is
                      the largest international membership organization dedicated to enhancing the knowledge, skills and
                      expertise of anti-money laundering (AML), counter-terrorism financing (CTF), and financial crime
                      detection and prevention professionals, whose members include representatives from a wide range of
                      financial institutions, regulatory bodies, law enforcement agencies and industry sectors. This tool
                      leverages their expertise to help our customers better understand their AML profile as it pertains
                      to Mastercard products (including virtual assets/crypto products and services), an organization’s
                      “high risk” customers, and geographies in which they do business.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Does this product satisfy my requirements under the Mastercard Rules?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      When completed accurately and updated regularly, Mastercard ACAMS Risk Assessment helps address
                      Mastercard Rule 1.2.1 as it pertains to risk assessment processes (Rule 1.2.1.4). The rule states,
                      that customers must have “Risk assessment processes designed to identify and apply appropriate risk
                      management controls.” Mastercard Rule 1.2.2 states customers must have a written sanctions compliance
                      Program that includes a policy, procedures, and controls. Mastercard ACAMS Risk Assessment is provided
                      as an option to satisfy Rule 1.2.1.4 and support your sanctions program, by facilitating a process
                      whereby institutions can assess their risk of encountering an OFAC/sanctions issue.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Who in my institution should evaluate the tool?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Mastercard ACAMS Risk Assessment should be evaluated by the AML Compliance Officer and/or your AML
                      Compliance Department lead, or their designee responsible for the organization’s AML risk assessment.
                      These positions would have the knowledge needed to decide how this tool can benefit your organization.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Will Mastercard see my risk assessment results?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Neither Mastercard nor ACAMS will see your results. Mastercard ACAMS Risk Assessment is intended to offer
                      customers worldwide a standardized means of evaluating their money laundering risks, and to facilitate the
                      development of risk mitigation action plans. We do not have visibility into your results. However, the tool
                      offers a wide-range of presentation-ready reports designed to support you in presenting to a variety of
                      stakeholders within and outside of your institution as needed.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <Typography className="faq-section-subtitle" variant="h6" gutterBottom={true} display="block">
                Benefits & Features
              </Typography>
              <div className="faq-accordion">
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      How is Mastercard ACAMS Risk Assessment different than other AML risk assessment applications?
                      OR “Is this a transaction monitoring system?“
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Mastercard ACAMS Risk Assessment is unique in that it offers a baseline standard for the inherent
                      risk of money laundering of an organization’s Mastercard products, services, delivery channels,
                      customer types, and geographies. Money laundering risk is evaluated using global standards and
                      best practices. The tool then facilitates completion of the assessment using guided selections and
                      categories. Once the assessment is completed, the applicable risk areas are scored. This provides
                      users with the ability to easily see their money laundering risk in each category. Additionally,
                      the use of the tool’s Sanctions Quantity of Risk assessment facilitates a process whereby institutions
                      can assess their risk of encountering an OFAC/sanctions issue.  The tool does not monitor transactions.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Can I present Mastercard ACAMS Risk Assessment reports to my auditor/regulator?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      While Mastercard ACAMS Risk Assessment only covers Mastercard products (including virtual assets/crypto
                      products and services), geographies in which your institution does business, and high-risk customer types,
                      the reports generated can be used as a supplement to other risk assessments for audits/examinations.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      I understand that in addition to the tool, my institution is also entitled to free ACAMS memberships,
                      can you provide more information?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Yes, as an additional benefit, up to five new free ACAMS memberships will be offered to active Mastercard
                      ACAMS Risk Assessment customers. The memberships will be for customer employees who are not already ACAMS
                      members. Membership includes access to special pricing on ACAMS trainings and events, webinars, bi-weekly
                      newsletters, and much more. Information about the ACAMS free memberships will be provided upon customer
                      registration. This benefit is not applicable to existing ACAMS members/memberships or renewals.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      How many users are allowed per subscription?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Customers can create accounts for up to 10 users within their institution (includes one Super
                      Administrator).
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Are there differing roles I can use to structure my risk assessment team?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Yes. The tool contains various roles that can be assigned to users: Super Administrator, Super Administrator
                      Proxy, Administrator, User, and Audit. These are permission based; for example, giving the ability to access,
                      create, and edit risk assessments, or the ability to create additional users.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Who should be assigned the role of Super Administrator?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      The Super Administrator should be the AML Compliance Officer and/or AML Compliance department lead or their
                      designee, as he or she will have administrative rights to the Mastercard ACAMS Risk Assessment account.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Can my institution create multiple risk assessments across the enterprise?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Yes, customers have the option to create one risk assessment across the enterprise, or to create separate
                      risk assessments by line of business, region, branch, or other characteristics.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Do you have a user’s guide?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Yes, a full online user’s help guide is available to all authorized subscribers within the tool itself.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      What types of reports are available for my institution to run?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Mastercard ACAMS Risk Assessment provides various reporting options including but not limited to:
                      <br></br>•	Standard single or multiple-assessment reports that measure AML risk
                      <br></br>•	Overall risk reports that provide one risk score across the institution
                      <br></br>•	Sanctions quantity of risk assessment
                      <br></br>•	Internal audit reports
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <Typography className="faq-section-subtitle" variant="h6" gutterBottom={true} display="block">
                Methodology
              </Typography>
              <div className="faq-accordion">
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      What information do I need to collect to conduct my risk assessment?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Customers will be required to enter information regarding the institution’s preventative and detective
                      controls. They will also be required to evaluate the strength of those controls along with the control
                      environment surrounding them. They will also have the option to input quantitative data if so desired
                      and will be required to create an action plan. The quantitative data is not used for scoring purposes,
                      they are entered for informational purposes only.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      How are products scored?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      For all products/services selected by the institution to conduct a risk assessment the user is guided
                      through a series of questions related to internal controls that are applied to mitigate risk. Starting
                      with a product’s/service’s inherent money laundering risk score, and considering the internal risk
                      mitigation controls selected by the user, a residual money laundering risk score is calculated.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      How are customer and geographical relationships scored?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      The high-risk customer type and geographical money laundering risk assessment is based on the concepts
                      of PROHIBITED and HIGH-RISK relationships with individual customers, business structure/industry customer
                      types, prohibited and high-risk geographies. The institution must first determine if such relationships exist.
                      Next the user is guided through a series of questions about internal controls.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
              <Typography className="faq-section-subtitle" variant="h6" gutterBottom={true} display="block">
                Technical Specifications
              </Typography>
              <div className="faq-accordion">
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      What are the system and technical requirements?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Mastercard ACAMS Risk Assessment requires: <br></br>• Desktop/Laptop - Windows XP or Later
                      <br></br>• Application supports the latest two versions of Chrome, Firefox and Internet Explorer,
                      provided the foregoing web browsers continue to be supported by the institution that developed such
                      web browser.  For the avoidance of doubt, the foregoing provision applies to any version.  By way of
                      example, latest two version of Chrome would be 10.8 and 10.9, not 10 and 11 (Please note this is a rolling
                      requirement and Compatibility Mode must be turned off).<br></br>• <strong>Important</strong>: Please take
                      note of the following to ensure the best User experience:<br></br>
                      <Typography className="faq-answer">
                        ∘ <strong>Mastercard ACAMS Risk Assessment does not support Browser Caching</strong>: Browser caching interferes
                        with the application functionality. Please turn off browser caching in your browser settings and/or confirm with
                        your Information Technology department that browser caching is turned off.<br></br>
                      </Typography>
                      <Typography className="faq-answer">
                        ∘{' '}
                        <strong>
                          Mastercard ACAMS Risk Assessment does not support access through Network Proxies
                        </strong>
                        : Access to the site via a network proxy server is not supported as it can cause performance issues and latency,
                        particularly when configured with proxy caching. Please confirm with your Information Technology department that
                        Network Proxies are not used.
                      </Typography>
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Where is my institution’s risk assessment data stored?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      Mastercard ACAMS Risk Assessment’s production servers are hosted at a Mastercard approved U.S. data center. Data center
                      holds multiple security certifications, including ISO 27001, PCI, and SOC 1 and SOC 2. The facility has received the
                      Uptime Institute Management and Operations (M&O) Stamp of Approval.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Is my institution’s data stored in the cloud?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">
                      No. Although Mastercard ACAMS Risk Assessment is accessed via the web, your institution’s data is hosted in a dedicated
                      physical server environment.
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
                <ExpansionPanel className="faq-panel">
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon className="accordion-arrow" />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                  >
                    <Typography className="faq-title" variant="h6">
                      Is the data encrypted?
                    </Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography className="faq-answer">Yes, data at rest and in-transit is encrypted.</Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
              </div>
            </div>
          </TabPanel>
          <TabPanel value={value} index={1}>
            <div>
              <a className="webinar" href="https://www.acams.org/en/training/webinars/do-you-know-the-money-laundering-vulnerabilities-in-your-credit-card-portfolio">
                <h2>Do You Know the Money-<br/>
                    Laundering Vulnerabilities in<br/>
                    Your Credit Card Portfolio?
                </h2>

                <p>Join ACAMS and Mastercard for a free webinar to learn more!</p>
                <br/>
                <br/>
                <span className="button">LEARN MORE</span>
              </a>
            </div>
            <div className="resource-cards">
              <div className="resource-card-container">
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      ACAMS Risk Assessment <br></br>
                      In Depth <br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfTwo} style={{ display: 'block' }}>
                      <img src={resourceTwo} alt="resoure2"></img>
                    </a>
                  </div>
                </div>
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      FATF Draft Guidance <br></br>
                      On Digital Identity <br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfSeven} style={{ display: 'block' }}>
                      <img src={resourceSeven} alt="resource7"></img>
                    </a>
                  </div>
                </div>
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      Bridging the gap between <br></br>
                      risk assessment and<br></br>
                      transaction monitoring <br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfOne} style={{ display: 'block' }}>
                      <img src={resourceOne} alt="resource1"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="resource-card-container-2">
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      Basel Committee Guidance on <br></br>
                      How to Manage ML and TR <br></br>
                      Risk – Essential Elements <br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfEight} style={{ display: 'block' }}>
                      <img src={resourceEight} alt="resource8"></img>
                    </a>
                  </div>
                </div>
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      A Deep Dive Into Country <br></br>
                      Risk Assessment <br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfTen} style={{ display: 'block' }}>
                      <img src={resourceTen} alt="resource10"></img>
                    </a>
                  </div>
                </div>
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      Exploring an Industry <br></br>
                      Wide Standard <br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfThree} style={{ display: 'block' }}>
                      <img src={resourceThree} alt="resource3"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="resource-card-container-2">
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      Guidance For A Risk- <br></br>
                      Based Approach <br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfNine} style={{ display: 'block' }}>
                      <img src={resourceNine} alt="resource9"></img>
                    </a>
                  </div>
                </div>
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      FATF – guidance for banking<br></br>
                      sector on the application <br></br>
                      of the risk-based approach<br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfFour} style={{ display: 'block' }}>
                      <img src={resourceFour} alt="resource4"></img>
                    </a>
                  </div>
                </div>
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      Basel Committee Guidance - <br></br>
                      Sound management of risks <br></br>
                      related to money laundering <br></br>
                      and financing of terrorism<br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfEleven} style={{ display: 'block' }}>
                      <img src={resourceEleven} alt="resource11"></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="resource-card-container-2">
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      The ML/TR risk assessment.<br></br>
                      Is it fit for purpose?<br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfSix} style={{ display: 'block' }}>
                      <img src={resourceSix} alt="resource6"></img>
                    </a>
                  </div>
                </div>
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      The “risk-based” principle<br></br>
                      of AML management<br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfTwelve} style={{ display: 'block' }}>
                      <img src={resourceTwelve} alt="resource12"></img>
                    </a>
                  </div>
                </div>
                <div className="resource-card">
                  <div className="resource-card-about">
                    <Typography variant="h5">
                      Wolfsberg AML Guidance on<br></br>
                      Credit/Chanrge Card Issuing<br></br>
                      and Merchant Acquiring<br></br>
                      Activities<br></br>
                      Article
                    </Typography>
                  </div>
                  <div className="resource-card-link">
                    <a href={pdfFive} style={{ display: 'block' }}>
                      <img src={resourceFive} alt="resource5"></img>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </TabPanel>
        </div>
        <Footer isAffiliate={isAffiliate}></Footer>
      </div>
    </React.Fragment>
  );
};

export enum ActivationOptOutRequiredFields {
  FINANCIAL_INSTITUTION = 'financialInstitution',
  PRINCIPAL_OR_AFFILIATE = 'principalOrAffiliate',
  //ICA = 'ica',
  MASTERCARD_ID = 'mastercardId'
}

export enum ActivationOptOutSponsoringICA {
  SPONSORING_ICA = 'sponsoringIca'
}

export enum ActivationOptOutPrimaryICA {
  PRIMARY_ICA = 'primaryIca'
}

export enum RegistrationDetailsRequiredFields {
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TITLE = 'title',
  EMAIL = 'email',
  PHONE = 'phone',
  ADDRESS_LINE_1 = 'addressLine1',
  CITY_OR_TOWN = 'cityOrTown',
  STATE_OR_PROVINCE_OR_REGION = 'stateOrProvinceOrRegion',
  COUNTRY = 'country'
}

export enum SuperAdminDetailsRequiredFields {
  SUPER_ADMIN_FIRST_NAME = 'superAdminFirstName',
  SUPER_ADMIN_LAST_NAME = 'superAdminLastName',
  SUPER_ADMIN_TITLE = 'superAdminTitle',
  SUPER_ADMIN_EMAIL = 'superAdminEmail',
  TOS_AGREED = 'tosAgreed'
}

export enum OptionalFields {
  ADDRESS_LINE_2 = 'addressLine2',
  ZIP_OR_POSTAL_CODE = 'zipOrPostalCode'
}

export enum AllRegistrationFields {
  FINANCIAL_INSTITUTION = 'financialInstitution',
  PRINCIPAL_OR_AFFILIATE = 'principalOrAffiliate',
  ICA = 'ica',
  MASTERCARD_ID = 'mastercardId',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TITLE = 'title',
  EMAIL = 'email',
  PHONE = 'phone',
  ADDRESS_LINE_1 = 'addressLine1',
  ADDRESS_LINE_2 = 'addressLine2',
  CITY_OR_TOWN = 'cityOrTown',
  ZIP_OR_POSTAL_CODE = 'zipOrPostalCode',
  STATE_OR_PROVINCE_OR_REGION = 'stateOrProvinceOrRegion',
  COUNTRY = 'country',
  SUPER_ADMIN_FIRST_NAME = 'superAdminFirstName',
  SUPER_ADMIN_LAST_NAME = 'superAdminLastName',
  SUPER_ADMIN_TITLE = 'superAdminTitle',
  SUPER_ADMIN_EMAIL = 'superAdminEmail',
  TOS_AGREED = 'tosAgreed',
  SUPER_ADMIN = 'superAdmin',
  SPONSORING_ICA = 'sponsoringIca',
  PRIMARY_ICA = 'primaryIca'
}

export enum DemoRegistrationFields {
  FINANCIAL_INSTITUTION = 'financialInstitution',
  MASTERCARD_ID = 'mastercardId',
  FIRST_NAME = 'firstName',
  LAST_NAME = 'lastName',
  TITLE = 'title',
  EMAIL = 'email',
  TOS_AGREED = 'tosAgreed'
}

export enum CsvUploadCredentialsFields {
  USERNAME = 'username',
  PASSWORD = 'password'
}

export enum PasswordFields {
  PASSWORD = 'password',
  PASSWORD_CONFIRMATION = 'passwordConfirmation'
}

export enum ContextGroup {
  REGISTRATION = 'registration', // Create other groups in this enum for other logically related items
  DEMO_REGISTRATION = 'demoRegistration',
  CSV_UPLOAD = 'csvUpload',
  PASSWORD_SET = 'passwordSet'
}

export enum SetActionTypes {
  STRING = 'SET_STRING',
  BOOLEAN = 'SET_BOOLEAN',
  NUMBER = 'SET_NUMBER',
  OBJECT = 'SET_OBJECT',
  ARRAY = 'SET_ARRAY',
  ANY = 'SET_ANY',
  RESET = 'RESET'
}

export enum PayloadTypes {
  STRING_PAYLOAD = 'stringPayload',
  BOOLEAN_PAYLOAD = 'booleanPayload',
  NUMBER_PAYLOAD = 'numberPayload',
  OBJECT_PAYLOAD = 'objectPayload',
  ARRAY_PAYLOAD = 'arrayPayload',
  ANY_PAYLOAD = 'anyPayload'
}

export type ActionType = {
  type: SetActionTypes;
  [PayloadTypes.STRING_PAYLOAD]?: string;
  [PayloadTypes.BOOLEAN_PAYLOAD]?: boolean;
  [PayloadTypes.NUMBER_PAYLOAD]?: number;
  [PayloadTypes.OBJECT_PAYLOAD]?: Record<string, any>;
  [PayloadTypes.ARRAY_PAYLOAD]?: any[];
  [PayloadTypes.ANY_PAYLOAD]?: any;
  group: ContextGroup; // This is a logical group name for a group of related values to keep track of (example: all registration fields)
  key: string; // This is the key used to store the value within the group object
};

export type StateType = {
  [ContextGroup.REGISTRATION]: Record<string, any> | undefined;
  [ContextGroup.DEMO_REGISTRATION]: Record<string, any> | undefined;
  [ContextGroup.CSV_UPLOAD]: Record<string, any> | undefined;
  [ContextGroup.PASSWORD_SET]: Record<string, any> | undefined;
};

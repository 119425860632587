import React, { useContext } from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { GroupedTextField } from '../shared/GroupedTextField';
import {
  ActivationOptOutPrimaryICA,
  ActivationOptOutRequiredFields,
  ActivationOptOutSponsoringICA
} from '../../constants/registration';
import { ContextGroup } from '../../constants/context';
import { GroupedRadioButton, RadioButtons } from '../shared/GroupedRadioButton';
import { Context } from '../context/Store';
import Link from '@material-ui/core/Link';
import { NameNumberRegex, NumberOnlyRegex } from '../../constants/regex';

interface ActivateOptOutProps {
  initialName?: string;
  initialPrincipalOrAffiliate?: string;
  initialPrimaryIca?: string;
  initialSponsoringIca?: string;
  initialMastercardId?: string;
  isAffiliate: boolean;
}
const radioButtons: RadioButtons = [
  {
    label: 'Principal Member',
    value: 'principal'
  },
  {
    label: 'Affiliate/Sponsored Member',
    value: 'affiliate'
  }
];

export const ActivationOptOut: React.FC<ActivateOptOutProps> = (props: ActivateOptOutProps) => {
  const {
    initialName,
    initialPrincipalOrAffiliate,
    initialPrimaryIca,
    initialSponsoringIca,
    initialMastercardId,
    isAffiliate
  } = props;
  const { state } = useContext(Context);

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="paper-registration">
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            {!isAffiliate && (
              <Typography component="h1" variant="h5" className="registrationTitle">
                Activate Registration
              </Typography>
            )}
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            {isAffiliate ? (
              <Typography variant="subtitle1" className="registrationNotice">
                Thank you for your interest. To activate your registration, please complete the form below.
              </Typography>
            ) : (
              <Typography variant="subtitle1" className="registrationNotice">
                Choose to activate registration or opt-out. Principals registering affiliates click{' '}
                <Link href={process.env.REACT_APP_AFFILIATE_LINK}>here</Link>, others follow the steps below.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" justify="center" className="step">
          <Grid item xs={12}>
            <GroupedTextField
              name={ActivationOptOutRequiredFields.FINANCIAL_INSTITUTION}
              label="Financial Institution Name"
              validationErrorMessage="Must be between 3-50 valid characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={ActivationOptOutRequiredFields.FINANCIAL_INSTITUTION}
              fullWidth={true}
              minLength={3}
              maxLength={50}
              regex={NameNumberRegex}
              initialValue={initialName}
              focus={true}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedRadioButton
              name={ActivationOptOutRequiredFields.PRINCIPAL_OR_AFFILIATE}
              label="Principal or Affiliate"
              groupName={ContextGroup.REGISTRATION}
              fieldName={ActivationOptOutRequiredFields.PRINCIPAL_OR_AFFILIATE}
              initialValue={initialPrincipalOrAffiliate || ''}
              buttonOptions={radioButtons}
            ></GroupedRadioButton>
          </Grid>
          {state[ContextGroup.REGISTRATION]![ActivationOptOutRequiredFields.PRINCIPAL_OR_AFFILIATE] === 'principal' && (
            <Grid item xs={12}>
              <GroupedTextField
                name={ActivationOptOutPrimaryICA.PRIMARY_ICA}
                label="Primary ICA #"
                validationErrorMessage="Invalid ICA #"
                required={true}
                groupName={ContextGroup.REGISTRATION}
                fieldName={ActivationOptOutPrimaryICA.PRIMARY_ICA}
                fullWidth={true}
                minLength={3}
                maxLength={20}
                regex={NumberOnlyRegex}
                initialValue={initialPrimaryIca}
              ></GroupedTextField>
            </Grid>
          )}
          {state[ContextGroup.REGISTRATION]![ActivationOptOutRequiredFields.PRINCIPAL_OR_AFFILIATE] === 'affiliate' && (
            <Grid item xs={12}>
              <GroupedTextField
                name={ActivationOptOutSponsoringICA.SPONSORING_ICA}
                label="Sponsoring ICA #"
                validationErrorMessage="Invalid ICA #"
                required={true}
                groupName={ContextGroup.REGISTRATION}
                fieldName={ActivationOptOutSponsoringICA.SPONSORING_ICA}
                fullWidth={true}
                minLength={3}
                maxLength={20}
                regex={NumberOnlyRegex}
                initialValue={initialSponsoringIca}
              ></GroupedTextField>
            </Grid>
          )}
          <Grid item xs={12}>
            <GroupedTextField
              name={ActivationOptOutRequiredFields.MASTERCARD_ID}
              label="Mastercard ID #"
              validationErrorMessage="Invalid Mastercard ID #"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={ActivationOptOutRequiredFields.MASTERCARD_ID}
              fullWidth={true}
              minLength={3}
              maxLength={20}
              regex={NumberOnlyRegex}
              initialValue={initialMastercardId}
            ></GroupedTextField>
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

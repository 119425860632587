import React from 'react';
import { RouteComponentProps } from '@reach/router';
import { NavigationBar } from './NavigationBar';
import Typography from '@material-ui/core/Typography';
import maraVideo from '../../assets/acams-mara-video-new.mp4';
import maraVideoScreenshot from '../../assets/video-screenshot.png';
import { Footer } from './Footer';
import Button from '@material-ui/core/Button';
import houseImg from '../../assets/icon-house.gif';
import cryptoImg from '../../assets/icon-crypto.gif';
import globeImg from '../../assets/icon-globe.gif';
import checkImg from '../../assets/icon-check.gif';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

export const Landing: React.FC<RouteComponentProps> = props => {
  const { location } = props;
  const isAffiliate = location?.pathname.endsWith('/affiliate') ? true : false;

  return (
    <React.Fragment>
      <NavigationBar isAffiliate={isAffiliate}></NavigationBar>
      <Box>
        <div className="landing-banner">
          <Container maxWidth={false} className="landing-box">
            <Container fixed>
              <Grid container justify="center" spacing={10} className="landing-grid">
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Typography variant="h4" gutterBottom={true} display="block" className="slogan">
                    Helping financial institutions worldwide assess their money laundering risk
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom={true} display="block" className="white-text">
                    Mastercard ACAMS Risk Assessment offers financial institutions the opportunity to assess their money
                    laundering risk as it relates to Mastercard products (including virtual asset/crypto products and services),
                    customer portfolio and countries of operation, through a solution that objectively and consistently
                    responds to the guidance and regulatory requirements of financial institution supervisors and regulators
                    world-wide.
                  </Typography>
                  <Button
                    variant="contained"
                    className="cta-button"
                    href={isAffiliate ? './affiliateRegister' : './register'}
                  >
                    Activate Registration
                  </Button>
                  {!isAffiliate && (
                    <Button
                      variant="contained"
                      className="cta-button"
                      href="https://form.mastercard.com/jfe/form/SV_8tRjaweWWwvXULb"
                    >
                      Opt Out
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6}>
                  <video controls poster={maraVideoScreenshot} className="landing-video">
                    <source src={maraVideo} type="video/mp4"></source>
                  </video>
                </Grid>
              </Grid>
            </Container>
          </Container>

          <div className="paper">
            <div className="landing-section">
              <Typography className="mara-subtitle" variant="h4" gutterBottom={true} display="block">
                Why Mastercard ACAMS Risk Assessment?
              </Typography>
              <Typography variant="subtitle1" gutterBottom={true} display="block" className="text">
                The Association of Certified Anti-Money Laundering Specialists® (ACAMS) is the largest international
                membership organization dedicated to enhancing the knowledge and skills of anti-money laundering (AML)
                and financial crime professionals from a wide range of industries. Mastercard, in partnership with
                ACAMS, provides financial institutions with peace of mind in AML compliance. The tool uses a timely,
                standardized methodology that is verifiable by global authoritative sources, including an AML risk
                assessment and a sanctions quantity of risk assessment.
              </Typography>
            </div>
            <Container maxWidth={false} className="landing-cards">
              <Container fixed>
                <div className="landing-section">
                    <Typography className="mara-subtitle" variant="h4" gutterBottom={true} display="block">
                        Three Unique Risk Assessments
                    </Typography>
                    <Typography variant="subtitle1" gutterBottom={true} display="block" className="text">
                        Mastercard ACAMS Risk Assessment goes well beyond simply providing an AML risk ranking by delivering
                        three unique risk assessments to support your institution’s regulatory obligations.
                    </Typography>
                </div>
                <Grid container justify="center" spacing={3} className="landing-card-container ">
                  <Grid item xs={12} sm={12} md={6} lg={4} style={{ display: 'flex' }}>
                    <Card variant="outlined" className="card">
                      <CardContent>
                        <img src={houseImg} className="card-icon" alt="house"></img>
                        <Typography variant="body2" gutterBottom={true} display="block">
                          The <strong>AML Risk assessment</strong> in response to global authoritative standards, offers your institution
                          an assessment of money laundering risk across your Mastercard portfolio of products, high-risk
                          customer types, and geographies.
                        </Typography>
                      </CardContent>
                      <CardActions />
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} style={{ display: 'flex' }}>
                    <Card variant="outlined" className="card">
                      <CardContent>
                        <img src={cryptoImg} className="crypto-card-icon" alt="crypto"></img>
                        <Typography variant="body2" gutterBottom={true} display="block">
                          The <strong>Crypto Risk assessment</strong> helps you assess your money laundering risk across your Mastercard
                          portfolio of virtual asset/crypto products and services, including the ability to assess customer
                          types that offer virtual asset products.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                  <Grid item xs={12} sm={12} md={6} lg={4} style={{ display: 'flex' }}>
                    <Card variant="outlined" className="card">
                      <CardContent>
                        <img src={globeImg} className="card-icon" alt="globe"></img>
                        <Typography variant="body2" gutterBottom={true} display="block">
                          The <strong>Sanctions Quantity of Risk assessment</strong> facilitates a process whereby your institution can assess
                          its risk of encountering an OFAC/sanctions issue. From customer base and transaction type risk, to
                          sanctions related regulatory actions, your institution will have a view into areas that may impact
                          risk mitigation programs.
                        </Typography>
                      </CardContent>
                    </Card>
                  </Grid>
                </Grid>
              </Container>
            </Container>
          </div>
          <Container maxWidth={false} className="landing-box">
            <Container fixed>
              <Grid container justify="center" spacing={10} className="landing-grid">
                <Grid item xs={12} sm={12} md={5} lg={5}>
                  <Typography variant="h4" gutterBottom={true} display="block" className="slogan">
                    Beyond Basic Risk Ranking
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom={true} display="block" className="white-text">
                    <Grid container spacing={1} style={{ display: 'flex', marginLeft: '-7.5%' }}>
                      <Grid item xs={1}>
                        <img src={checkImg} className="check-icon" alt="check"></img>
                      </Grid>
                      <Grid item xs={11}>
                        Multi-user web-based solution allows for timely, seamless updates to keep up with changing
                        regulatory requirements.
                      </Grid>
                      <Grid item xs={1}>
                        <img src={checkImg} className="check-icon" alt="check"></img>
                      </Grid>
                      <Grid item xs={11}>
                        Automated presentation-ready reports clearly communicate risk, from executive summaries to
                        comprehensive enterprise reports.
                      </Grid>
                      <Grid item xs={1}>
                        <img src={checkImg} className="check-icon" alt="check"></img>
                      </Grid>
                      <Grid item xs={11}>
                        Narrative input fields facilitate qualitative reviews of institution's controls and control
                        effectiveness, further supporting risk identification.
                      </Grid>
                      <Grid item xs={1}>
                        <img src={checkImg} className="check-icon" alt="check"></img>
                      </Grid>
                      <Grid item xs={11}>
                        Action plan input fields help address and communicate compliance deficiencies.
                      </Grid>
                    </Grid>
                  </Typography><br/>
                  <Button
                    variant="contained"
                    className="cta-button"
                    href={isAffiliate ? './affiliateRegister' : './register'}
                    style={{marginLeft: '8.5%' }}
                  >
                    Activate Registration
                  </Button>
                  {!isAffiliate && (
                    <Button
                      variant="contained"
                      className="cta-button"
                      href="https://form.mastercard.com/jfe/form/SV_8tRjaweWWwvXULb"
                    >
                      Opt Out
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Container>
          </Container>
          <Footer isAffiliate={isAffiliate}></Footer>
        </div>
      </Box>
    </React.Fragment>
  );
};

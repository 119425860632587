import Typography from '@material-ui/core/Typography';
import React from 'react';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';

interface FooterProps {
  isAffiliate: boolean;
}

export const Footer: React.FC<FooterProps> = props => {
  const { isAffiliate } = props;

  return (
    <React.Fragment>
      <Container maxWidth={false} className="acams-footer">
        <Container fixed>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={12} sm={12} md={6} lg={4} style={{ display: 'flex' }}>
              <Typography className="about-acams" variant="body2" gutterBottom={true} display="block">
                <strong style={{ fontSize: '1em !important' }}>Questions?</strong> <br></br>
                <br></br>
                Customers with questions about <br></br>
                Mastercard ACAMS Risk Assessment <br></br>
                should contact{' '}
                <a href="mailto:FinancialCrimeHelpdesk@mastercard.com" style={{ color: '#454545' }}>
                  FinancialCrimeHelpdesk@mastercard.com.
                </a>
                <br></br>
                <br></br>
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={2} style={{ display: 'flex', marginLeft: '-2.5%' }}>
              <Typography variant="subtitle2" gutterBottom={true} display="block" className="text">
                <strong style={{ fontSize: '1em !important' }}>Next Steps</strong> <br></br>
                <br></br>
                <Link href="./register" style={{ color: '#454545', textDecoration: 'underline' }}>
                  Activate Registration
                </Link>
                <br></br>
                {!isAffiliate && (
                  <Link
                    href={process.env.REACT_APP_OPT_OUT_LINK}
                    style={{ color: '#454545', textDecoration: 'underline' }}
                  >
                    Opt out
                  </Link>
                )}
              </Typography>
            </Grid>
          </Grid>
          <div style={{ borderBottom: '1px solid #DDDEDF', width: '47%', margin: '3.5% auto 1%' }}></div>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={6} justify="center" style={{ display: 'flex' }}>
              <Typography variant="body2" className="copyright">
                Copyright © 2023 Mastercard and ACAMS. All Rights Reserved.
              </Typography>
            </Grid>
          </Grid>
        </Container>
      </Container>
    </React.Fragment>
  );
};

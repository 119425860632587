import React, { useEffect } from 'react';
import { NavigationBar } from './NavigationBar';
import Typography from '@material-ui/core/Typography';
import { Footer } from './Footer';
import leftPopup from '../../assets/benefits-left-detail.gif';
import rightPopup from '../../assets/overall-risk-popup.png';
import leftPopupIcon from '../../assets/benefits-left-thumbnail.gif';
import rightPopupIcon from '../../assets/overall-risk-thumbnail.png';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';
import Box from '@material-ui/core/Box';
import acamsShield from '../../assets/acams-shield.svg';

import maraVideoScreenshot from '../../assets/video-screenshot.png';
import maraVideo from '../../assets/acams-mara-video-new.mp4';

const modalStyle = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute'
} as React.CSSProperties;

interface TrainingProps {
  isAffiliate: boolean;
  path: string;
}

export const Training: React.FC<TrainingProps> = (props: TrainingProps) => {
  const { isAffiliate } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<string>();
  useEffect(() => {
    document.title = 'Training | Mastercard ACAMS Risk Assessment';
  }, []);

  const openLeftImage = () => {
    setSelectedImage(leftPopup);
    setOpen(true);
  };

  const openRightImage = () => {
    setSelectedImage(rightPopup);
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={close}>
        <img style={modalStyle} src={selectedImage} className="enlarged-image" alt="selected" />
      </Modal>
      <NavigationBar isAffiliate={isAffiliate}></NavigationBar>
        <div className="landing-banner">
                <Container maxWidth={false} className="landing-box">
                  <Container fixed>
                    <Grid container justify="center" spacing={10} className="landing-grid">
                      <Grid item xs={12} sm={12} md={5} lg={10}>
                        <Typography variant="h4" gutterBottom={true} display="block" className="slogan">
                          Meet Your Anti-Financial Crime Training Requirements
                        </Typography><br/>
                        <Typography variant="subtitle1" gutterBottom={true} display="block" className="white-text">
                          Mastercard has expanded its partnership with ACAMS, a global leader in anti-financial crime
                          education, to help safeguard FinTechs and financial institutions around the world from
                          criminal exploitation and regulatory risks.
                        </Typography><br/>
                        <Typography variant="subtitle1" gutterBottom={true} display="block" className="white-text">
                            Mastercard is providing Customers a means to meet global anti-money laundering and
                            counter-terrorism financing (CTF) standards through internationally recognized ACAMS
                            education and certifications. ACAMS training covers topics including anti-financial crime controls and
                            best practices for community institutions, small and midsize FinTech companies, and financial institutions.
                        </Typography><br/>
                        <Typography variant="subtitle1" gutterBottom={true} display="block" className="white-text">
                            ACAMS' anti-financial crime education helps Customers meet anti-money laundering training requirements
                            outlined in Mastercard Network Rule 1.2.
                        </Typography>
                      </Grid>
                    </Grid>
                  </Container>
                </Container>

        <Container maxWidth={false} className="small-card-container-box">
          <Container fixed>
                          <div className="landing-section">
                            <Typography className="mara-subtitle" variant="h4" gutterBottom={true} display="block">
                              Cost-Effective Anti-financial Crime Training
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom={true} display="block" className="text">
                              Anti-money Laundering Training budgets are tight, regulatory scrutiny is increasing, and so is the pressure
                              to keep anti-financial crime teams knowledgeable about money laundering risks, regulatory changes, and
                              constant innovation.
                            </Typography>
                            <br/>
                            <Typography variant="subtitle1" gutterBottom={true} display="block" className="text">
                              Mastercard and ACAMS offer pre-built, cost-effective, stackable training packages.
                              These packages enable FinTechs, community institutions, and financial institutions to meet
                              training needs and upskill compliance teams on essential anti-financial crime principles
                              that meet regulatory requirements.
                            </Typography>
                            <Typography variant="subtitle1" gutterBottom={true} display="block" className="text-bold-relative">
                              Protect your business and develop your anti-money laundering Team with regulator recognized ACAMS training
                              and certifications. Learn more about our customized training packages:
                            </Typography>
                          </div>
            <Grid container justify="center" spacing={4} className="small-card-container">
              <Grid item xs={12} sm={12} md={6} lg={3} style={{ display: 'flex', marginRight: '1.75%' }}>
                <Card variant="outlined" className="small-benefits-card">
                  <CardContent>
                    <Typography className="mara-card-subtitle" variant="h6" gutterBottom={true} display="block">
                      <a href="https://www.acams.org/en/training-for-fintechs" className="a-href-training-text">Anti-financial Crime Training for FinTechs</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom={true} display="block">
                      Training designed to cover FinTechs' anti-money laundering educational needs from baseline training to advanced certifications.
                      <ul>
                        <li>Anti-money Laundering Foundations for FinTech</li>
                        <li>Certified Anti-money Laundering FinTech Compliance Associate (CAFCA)</li>
                        <li>+ More</li>
                      </ul>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                style={{ display: 'flex', marginLeft: '1.75%', marginRight: '1.75%' }}
              >
                <Card variant="outlined" className="small-benefits-card">
                  <CardContent>
                    <Typography className="mara-card-subtitle" variant="h6" gutterBottom={true} display="block">
                      <a href="https://www.acams.org/en/training-for-community-and-local-banks" className="a-href-training-text">Anti-financial Crime Training for Community and Financial Institutions</a>
                    </Typography>
                    <Typography variant="body2" gutterBottom={true} display="block">
                      Training designed for community institutions, including anti-money laundering training that is relevant to your
                      compliance needs.
                      <ul>
                        <li>Anti-money Laundering Foundations</li>
                        <li>Certified Know Your Customer Associate (CKYCA)</li>
                        <li>Certified Anti-Money Laundering Specialist (CAMS)</li>
                        <li>+ More</li>
                      </ul>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
            <div className="after-cards-section">
              <Typography variant="subtitle1" gutterBottom={true} display="block" className="text-bold-relative">
                Anti-financial Crime training is available for other Mastercard customers to help with Mastercard Rules
                compliance, <a href="https://www.acams.org/en/anti-financial-crime-training?auHash=AvgnkeDm0lsw5zCdNHti1mHhzIvLCPXe-Zrki0u5ovU" className="a-href-training-text">click here</a>.
              </Typography>
            </div>
          </Container>
        </Container>

        <div className="join-box">
          <div className="landing-section">
            <img src={acamsShield} className="acams-shield" alt="acams-shield" />
            <Typography className="mara-subtitle" variant="h4" gutterBottom={true} display="block">
              Interested in joining ACAMS?
            </Typography>
            <Typography variant="subtitle1" gutterBottom={true} display="block">
              Provide your anti-financial crime team the tools they need to succeed with ACAMS Enterprise – a premium
              professional membership package for any organization involved with anti-financial crime.
              Comprising all the benefits of ACAMS individual membership, Enterprise also includes exclusive,
              unlimited access to moneylaundering.com, plus ACAMS’ full webinar training catalogue, and the
              reporting tools to enable you to prove compliance to regulators.
              <a href="https://www.acams.org/en/membership/acams-enterprise-membership" className="a-href-text">Learn more.</a>
            </Typography>
            <br/>
            <Typography variant="subtitle1" gutterBottom={true} display="block" className="landing-section-italic">
              ACAMS is a leading international membership organization dedicated to providing opportunities
              for anti-financial crime education, best practices, and peer-to-peer networking to anti-financial crime
              professionals globally. With over 100,000 members across 180 jurisdictions, ACAMS is committed
              to the mission of ending financial crime through the provision of anti-money
              laundering/counter-terrorism-financing and sanctions knowledge-sharing, thought leadership,
              risk-mitigation services, ESG initiatives, and platforms for public-private dialogue. Disclaimer:
              Mastercard is a referral partner of ACAMS. The ACAMS Education service is provided to customers by ACAMS.
            </Typography>
          </div>
        </div>

        <Footer isAffiliate={isAffiliate}></Footer>
      </div>
    </React.Fragment>
  );
};

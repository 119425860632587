import * as EmailValidator from 'email-validator';

const rejectedDomains = ['gmail.com', 'hotmail.com', 'yahoo.com', 'aol.com', 'outlook.com', 'mail.com', 'msn.com'];

export const emailValidator = (input: string): boolean => {
  const validEmail = EmailValidator.validate(input);
  let validDomain = true;
  rejectedDomains.forEach(domain => {
    if (input.endsWith(domain)) {
      validDomain = false;
    }
  });
  return validEmail && validDomain;
};

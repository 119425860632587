import { ActionType, PayloadTypes, SetActionTypes, StateType } from '../../constants/context';

const Reducer = (state: StateType, action: ActionType): StateType => {
  const updateState = (state: StateType, payload: PayloadTypes): StateType => {
    return {
      ...state,
      [action.group]: {
        ...state[action.group],
        [action.key]: action[payload]
      }
    };
  };

  switch (action.type) {
    case SetActionTypes.STRING:
      return updateState(state, PayloadTypes.STRING_PAYLOAD);
    case SetActionTypes.BOOLEAN:
      return updateState(state, PayloadTypes.BOOLEAN_PAYLOAD);
    case SetActionTypes.NUMBER:
      return updateState(state, PayloadTypes.NUMBER_PAYLOAD);
    case SetActionTypes.OBJECT:
      return updateState(state, PayloadTypes.OBJECT_PAYLOAD);
    case SetActionTypes.ANY:
      return updateState(state, PayloadTypes.ANY_PAYLOAD);
    case SetActionTypes.RESET:
      return {
        ...state,
        [action.group]: {}
      };
    default:
      return state;
  }
};

export default Reducer;

import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { Notification, NotificationSeverity } from './shared/Notification';
import { ContextGroup } from '../constants/context';
import { Button } from '@material-ui/core';
import { CsvUploadCredentialsFields } from '../constants/registration';
import { Context } from './context/Store';
import Grid from '@material-ui/core/Grid';
import { RouteComponentProps } from '@reach/router';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { GroupedTextField } from './shared/GroupedTextField';
import { DropzoneArea } from 'material-ui-dropzone';
import * as base64 from 'base-64';

export const AuthorizationCsvUpload: React.FC<RouteComponentProps> = () => {
  const { state } = useContext(Context);
  const [validated, setValidated] = useState(false);
  const [sent, setSent] = useState(false);
  const [hasError, setError] = useState(false);
  const [files, setFiles] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const csvUploadState = state[ContextGroup.CSV_UPLOAD];

  const handleUpload = async () => {
    try {
      const login = state[ContextGroup.CSV_UPLOAD]![CsvUploadCredentialsFields.USERNAME];
      const password = state[ContextGroup.CSV_UPLOAD]![CsvUploadCredentialsFields.PASSWORD];
      const file = new FormData();
      file.append('file', files![0]);

      const response = await fetch(process.env.REACT_APP_BACKEND_API_BASE_URL + '/demo-registrations/authorize', {
        method: 'POST',
        headers: new Headers({
          Authorization: `Basic ${base64.encode(`${login}:${password}`)}`
        }),
        body: file
      });

      if (!response.ok) {
        // fetch does not fail promises unless there's a connection error
        throw Error(response.statusText);
      }

      setSent(true);
      setError(false);
    } catch (error) {
      setError(true);
      setSent(false);
      setErrorMessage(error.message);
    }
  };

  const uploadChange = (files: any) => {
    setFiles(files);
  };

  useEffect(() => {
    let validated = true;
    // Make sure all required fields are present
    Object.values(CsvUploadCredentialsFields).forEach(fieldName => {
      if (!state[ContextGroup.CSV_UPLOAD]![fieldName]) {
        validated = false;
      }
    });

    setValidated(validated);
  }, [csvUploadState, state]);

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xl" className="registration-container">
        <Grid container justify="center">
          <Grid item xs={8} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <div>
              <Notification
                show={sent}
                notificationTitle="Success"
                message={<React.Fragment>Your csv has been uploaded</React.Fragment>}
                severity={NotificationSeverity.SUCCESS}
              />
              <Notification
                show={hasError}
                notificationTitle="Upload Error"
                message={errorMessage}
                severity={NotificationSeverity.ERROR}
              />
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} direction="column" style={{ display: 'flex' }}>
            <Container component="main" maxWidth="xs">
              <CssBaseline />
              <div className="paper-registration">
                <Grid container justify="center" spacing={3}>
                  <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '2.5%' }}>
                    <Typography component="h1" variant="h5" className="registrationTitle">
                      Registration Authorization CSV Upload
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container spacing={2} alignItems="center" justify="center" className="step">
                  <Grid item xs={12}>
                    <GroupedTextField
                      name={CsvUploadCredentialsFields.USERNAME}
                      label="Username"
                      required={true}
                      groupName={ContextGroup.CSV_UPLOAD}
                      fieldName={CsvUploadCredentialsFields.USERNAME}
                      fullWidth={true}
                      focus={true}
                    ></GroupedTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <GroupedTextField
                      name={CsvUploadCredentialsFields.PASSWORD}
                      label="Password"
                      required={true}
                      groupName={ContextGroup.CSV_UPLOAD}
                      fieldName={CsvUploadCredentialsFields.PASSWORD}
                      fullWidth={true}
                      type="password"
                    ></GroupedTextField>
                  </Grid>
                  <Grid item xs={12}>
                    <DropzoneArea
                      onChange={uploadChange}
                      acceptedFiles={['text/csv', '.csv']}
                      showPreviews={true}
                      filesLimit={1}
                    />
                  </Grid>
                </Grid>
              </div>
            </Container>
            <Container maxWidth="xs">
              <Grid container justify="center">
                <Grid item xs={12}>
                  <Button
                    className="full-width-button"
                    variant="contained"
                    color="primary"
                    onClick={handleUpload}
                    // Disable the button if fields are not filled in and file is not uploaded
                    disabled={!validated || files === null || (files !== null && files![0] === undefined)}
                  >
                    Upload
                  </Button>
                </Grid>
              </Grid>
            </Container>
          </Grid>
        </Grid>
      </Container>
    </React.Fragment>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Container from '@material-ui/core/Container';
import { Context } from './context/Store';
import { ContextGroup, SetActionTypes } from '../constants/context';
import {
  ActivationOptOutPrimaryICA,
  ActivationOptOutRequiredFields,
  ActivationOptOutSponsoringICA,
  AllRegistrationFields,
  OptionalFields,
  RegistrationDetailsRequiredFields,
  SuperAdminDetailsRequiredFields
} from '../constants/registration';
import { Notification, NotificationSeverity } from './shared/Notification';
import '../css/Registration.scss';
import { NavigationBar } from './static/NavigationBar';
import Recaptcha from 'react-recaptcha';
import { Button } from '@material-ui/core';
import { Footer } from './static/Footer';
import { ActivationOptOut } from './registration/ActivationOptOut';
import Grid from '@material-ui/core/Grid';
import { RegistrationDetails } from './registration/RegistrationDetails';
import { SuperAdminDetails } from './registration/SuperAdminDetails';

interface RegisterProps {
  isAffiliate: boolean;
  path: string;
}

export const Register: React.FC<RegisterProps> = props => {
  const { isAffiliate } = props;
  const { state, dispatch } = useContext(Context);
  const [activeStep, setActiveStep] = useState(0);
  const [registered, setRegistered] = useState(false);
  const [hasError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    document.title = isAffiliate
      ? 'Register | Mastercard ACAMS Risk Assessment'
      : 'Participation Options | Mastercard ACAMS Risk Assessment';
  }, [isAffiliate]);

  const steps = isAffiliate
    ? ['Activate Registration', 'Registration Details', 'Super Administrator Details']
    : ['Activate Registration/Opt-out', 'Registration Details', 'Super Administrator Details'];

  const deleteStoredPayload = () => {
    dispatch({
      type: SetActionTypes.OBJECT,
      group: ContextGroup.REGISTRATION,
      key: 'STORED_REQUEST',
      objectPayload: undefined
    });
  };
  // Because components used by the stepper are mounted each time (and thus don't retain state), state needs to be retrieved from central store
  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <ActivationOptOut
            isAffiliate={isAffiliate}
            initialName={state[ContextGroup.REGISTRATION]![ActivationOptOutRequiredFields.FINANCIAL_INSTITUTION]}
            initialPrimaryIca={state[ContextGroup.REGISTRATION]![ActivationOptOutPrimaryICA.PRIMARY_ICA]}
            initialSponsoringIca={state[ContextGroup.REGISTRATION]![ActivationOptOutSponsoringICA.SPONSORING_ICA]}
            initialMastercardId={state[ContextGroup.REGISTRATION]![ActivationOptOutRequiredFields.MASTERCARD_ID]}
            initialPrincipalOrAffiliate={
              state[ContextGroup.REGISTRATION]![ActivationOptOutRequiredFields.PRINCIPAL_OR_AFFILIATE]
            }
          ></ActivationOptOut>
        );
      case 1:
        return (
          <RegistrationDetails
            isAffiliate={isAffiliate}
            initialFirstName={state[ContextGroup.REGISTRATION]![RegistrationDetailsRequiredFields.FIRST_NAME]}
            initialLastName={state[ContextGroup.REGISTRATION]![RegistrationDetailsRequiredFields.LAST_NAME]}
            initialTitle={state[ContextGroup.REGISTRATION]![RegistrationDetailsRequiredFields.TITLE]}
            initialEmail={state[ContextGroup.REGISTRATION]![RegistrationDetailsRequiredFields.EMAIL]}
            initialPhone={state[ContextGroup.REGISTRATION]![RegistrationDetailsRequiredFields.PHONE]}
            initialAddress1={state[ContextGroup.REGISTRATION]![RegistrationDetailsRequiredFields.ADDRESS_LINE_1]}
            initialAddress2={state[ContextGroup.REGISTRATION]![AllRegistrationFields.ADDRESS_LINE_2]}
            initialCityOrTown={state[ContextGroup.REGISTRATION]![RegistrationDetailsRequiredFields.CITY_OR_TOWN]}
            initialPostalCode={state[ContextGroup.REGISTRATION]![AllRegistrationFields.ZIP_OR_POSTAL_CODE]}
            initialStateOrProvinceOrRegion={
              state[ContextGroup.REGISTRATION]![RegistrationDetailsRequiredFields.STATE_OR_PROVINCE_OR_REGION]
            }
            initialCountry={state[ContextGroup.REGISTRATION]![RegistrationDetailsRequiredFields.COUNTRY]}
            initialSuperAdmin={state[ContextGroup.REGISTRATION]![AllRegistrationFields.SUPER_ADMIN]}
            /*
            remove the stored request in case they click submit, ignore the captcha,
            then reset SA/TOS to prevent unwanted checkbox rendering
             */
            onSuperAdminCheckboxChange={deleteStoredPayload}
          ></RegistrationDetails>
        );
      case 2:
        return (
          <SuperAdminDetails
            initialFirstName={state[ContextGroup.REGISTRATION]![SuperAdminDetailsRequiredFields.SUPER_ADMIN_FIRST_NAME]}
            initialLastName={state[ContextGroup.REGISTRATION]![SuperAdminDetailsRequiredFields.SUPER_ADMIN_LAST_NAME]}
            initialTitle={state[ContextGroup.REGISTRATION]![SuperAdminDetailsRequiredFields.SUPER_ADMIN_TITLE]}
            initialEmail={state[ContextGroup.REGISTRATION]![SuperAdminDetailsRequiredFields.SUPER_ADMIN_EMAIL]}
          ></SuperAdminDetails>
        );
      default:
        return 'Unknown step';
    }
  };

  const validateForm = (
    formGroup:
      | typeof ActivationOptOutRequiredFields
      | typeof ActivationOptOutPrimaryICA
      | typeof ActivationOptOutSponsoringICA
      | typeof RegistrationDetailsRequiredFields
      | typeof SuperAdminDetailsRequiredFields
  ) => {
    let validated = true;
    // Make sure all required fields are present
    Object.values(formGroup).forEach(fieldName => {
      if (!state[ContextGroup.REGISTRATION]![fieldName]) {
        validated = false;
        dispatch({
          type: SetActionTypes.BOOLEAN,
          group: ContextGroup.REGISTRATION,
          key: `${fieldName}Error`,
          booleanPayload: true
        });
      }
      // Make sure present fields don't contain errors
      if (state[ContextGroup.REGISTRATION]![`${fieldName}Error`]) {
        validated = false;
      }
    });
    // Make sure non-required fields and required fields aren't in error state
    Object.values(OptionalFields).forEach(optionalFieldName => {
      if (state[ContextGroup.REGISTRATION]![`${optionalFieldName}Error`]) {
        validated = false;
      }
    });
    return validated;
  };

  const validateCurrentStep = (step: number) => {
    switch (step) {
      case 0:
        return state[ContextGroup.REGISTRATION]![ActivationOptOutRequiredFields.PRINCIPAL_OR_AFFILIATE] === 'principal'
          ? validateForm(ActivationOptOutRequiredFields) && validateForm(ActivationOptOutPrimaryICA)
          : validateForm(ActivationOptOutRequiredFields) && validateForm(ActivationOptOutSponsoringICA);
      case 1:
        return validateForm(RegistrationDetailsRequiredFields);
      case 2:
        return validateForm(SuperAdminDetailsRequiredFields);
      default:
        return true;
    }
  };

  const submit = async () => {
    const payload: { [key: string]: any } = {};
    Object.values(AllRegistrationFields).forEach((fieldName: string) => {
      const fieldValue = state[ContextGroup.REGISTRATION]![fieldName];
      if (fieldValue && fieldValue.length > 0) {
        payload[fieldName] =
          typeof fieldValue === 'string' || fieldValue instanceof String ? fieldValue.trim() : fieldValue;
      }
    });
    payload[AllRegistrationFields.TOS_AGREED] = true;
    // Remove unused ICA #
    switch (state[ContextGroup.REGISTRATION]![ActivationOptOutRequiredFields.PRINCIPAL_OR_AFFILIATE]) {
      case 'principal':
        // keep primary and remove sponsoring ica (in case user accidentally filled out both
        delete payload[ActivationOptOutSponsoringICA.SPONSORING_ICA];
        break;
      case 'affiliate':
        // keep sponsoring and remove primary ica
        delete payload[ActivationOptOutPrimaryICA.PRIMARY_ICA];
        break;
      default:
        break;
    }
    // copy over values for super admin if the registrant isn't one
    if (state[ContextGroup.REGISTRATION]![AllRegistrationFields.SUPER_ADMIN]) {
      payload[SuperAdminDetailsRequiredFields.SUPER_ADMIN_FIRST_NAME] = state[ContextGroup.REGISTRATION]![
        RegistrationDetailsRequiredFields.FIRST_NAME
      ].trim();
      payload[SuperAdminDetailsRequiredFields.SUPER_ADMIN_LAST_NAME] = state[ContextGroup.REGISTRATION]![
        RegistrationDetailsRequiredFields.LAST_NAME
      ].trim();
      payload[SuperAdminDetailsRequiredFields.SUPER_ADMIN_TITLE] = state[ContextGroup.REGISTRATION]![
        RegistrationDetailsRequiredFields.TITLE
      ].trim();
      payload[SuperAdminDetailsRequiredFields.SUPER_ADMIN_EMAIL] = state[ContextGroup.REGISTRATION]![
        RegistrationDetailsRequiredFields.EMAIL
      ].trim();
    }
    dispatch({
      type: SetActionTypes.OBJECT,
      group: ContextGroup.REGISTRATION,
      key: 'STORED_REQUEST',
      objectPayload: payload
    });
  };

  const handleNext = async () => {
    if (validateCurrentStep(activeStep)) {
      // check if it's the last form (case 2) or if they're a super admin and accepted TOS, proceed with submitting
      if (
        activeStep === 2 ||
        (state[ContextGroup.REGISTRATION]![SuperAdminDetailsRequiredFields.TOS_AGREED] &&
          state[ContextGroup.REGISTRATION]![AllRegistrationFields.SUPER_ADMIN] &&
          activeStep === 1)
      ) {
        await submit();
        return;
      }
      setActiveStep(prevActiveStep => prevActiveStep + 1);
      window.scrollTo(0, 0);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const onCaptchaSuccess = async (response: string) => {
    const storedPayload = state[ContextGroup.REGISTRATION]!['STORED_REQUEST'];
    storedPayload.captchaUserResponseToken = response;
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_API_BASE_URL + '/registrations', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(storedPayload)
      });
      if (!response.ok) {
        // fetch does not fail promises unless there's a connection error
        throw Error(response.statusText);
      }
      setRegistered(true);
      setActiveStep(steps.length);
    } catch (error) {
      setError(true);
      setErrorMessage(error.message);
    }
  };

  return (
    <React.Fragment>
      <NavigationBar isAffiliate={isAffiliate}></NavigationBar>
      <Container component="main" maxWidth="xl" className="registration-container">
        <Grid container justify="center" spacing={3} style={{ width: '100%' }}>
          <Grid item xs={12} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <Stepper activeStep={activeStep} className="stepper">
              {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode } = {};
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
          </Grid>
        </Grid>
        {activeStep === steps.length ? (
          <Grid container justify="center">
            <Grid item xs={8} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
              <div>
                <Notification
                  show={registered}
                  notificationTitle="Thank you for registering."
                  message={
                    <React.Fragment>
                      The person listed as the Super Administrator will will receive an email with login information for
                      the tool during your region's roll-out period. <br></br>
                      <br></br>In the meantime, if you have any questions, please contact Customer Support at
                      <a href="mailto:FinancialCrimeHelpdesk@mastercard.com">FinancialCrimeHelpdesk@mastercard.com.</a>
                    </React.Fragment>
                  }
                  severity={NotificationSeverity.SUCCESS}
                />
                <Notification
                  show={hasError}
                  notificationTitle="Registration Error"
                  message={errorMessage}
                  severity={NotificationSeverity.ERROR}
                />
              </div>
            </Grid>
          </Grid>
        ) : (
          <div>
            <Grid container justify="center">
              <Grid item xs={8} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
                <div style={{ width: '100%' }}>
                  <Notification
                    show={hasError}
                    notificationTitle="Registration Error"
                    message={errorMessage}
                    severity={NotificationSeverity.ERROR}
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container justify="center" spacing={3}>
              <Grid item xs={12} sm={12} md={6} direction="column" style={{ display: 'flex' }}>
                {getStepContent(activeStep)}
                <Container maxWidth="xs">
                  {state[ContextGroup.REGISTRATION]!['STORED_REQUEST'] &&
                    state[ContextGroup.REGISTRATION]![AllRegistrationFields.TOS_AGREED] && (
                      <Recaptcha
                        sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                        render="explicit"
                        verifyCallback={onCaptchaSuccess}
                      />
                    )}
                  <Grid container justify="center" spacing={3}>
                    {activeStep !== 0 && (
                      <Grid item xs={4}>
                        <Button className="full-width-button" onClick={handleBack} variant="contained" color="primary">
                          Back
                        </Button>
                      </Grid>
                    )}
                    <Grid item xs={activeStep !== 0 ? 8 : 12} style={{ marginBottom: '3%' }}>
                      <Button
                        className="full-width-button"
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        // Disable the button on the last step if the terms of service are not agreed to
                        disabled={
                          (!state[ContextGroup.REGISTRATION]![SuperAdminDetailsRequiredFields.TOS_AGREED] &&
                            activeStep === steps.length - 1) ||
                          (state[ContextGroup.REGISTRATION]![AllRegistrationFields.SUPER_ADMIN] &&
                            !state[ContextGroup.REGISTRATION]![SuperAdminDetailsRequiredFields.TOS_AGREED] &&
                            activeStep === steps.length - 2)
                        }
                      >
                        {/* Show Activate Registration if the user checked the super admin checkbox. (Skip step 3) */}
                        {state[ContextGroup.REGISTRATION]![AllRegistrationFields.SUPER_ADMIN] &&
                        activeStep === steps.length - 2
                          ? 'Activate Registration'
                          : activeStep === steps.length - 1
                          ? 'Activate Registration'
                          : `${activeStep === 0 ? 'Continue to step 2' : 'Continue'}`}
                      </Button>
                    </Grid>
                  </Grid>
                  {!isAffiliate && activeStep < 1 && (
                    <Grid container justify="center">
                      <Grid item xs={12}>
                        <div className="opt-out-button-div">
                          <Button
                            className="full-width-button"
                            color="primary"
                            variant="contained"
                            href={process.env.REACT_APP_OPT_OUT_LINK}
                          >
                            Opt Out
                          </Button>
                        </div>
                      </Grid>
                    </Grid>
                  )}
                </Container>
              </Grid>
            </Grid>
          </div>
        )}
      </Container>
      <Footer isAffiliate={isAffiliate}></Footer>
    </React.Fragment>
  );
};

import React, { useContext, useRef } from 'react';
import TextField from '@material-ui/core/TextField';
import { Context } from '../context/Store';
import { ContextGroup, SetActionTypes } from '../../constants/context';
import { Tooltip } from '@material-ui/core';
import withStyles from '@material-ui/core/styles/withStyles';

interface GroupedTextFieldProps {
  name: string;
  label: string;
  validationErrorMessage?: string;
  required: boolean;
  groupName: ContextGroup; // this will be used to logically group inputs together in the context/state
  fieldName: string;
  fullWidth: boolean;
  minLength?: number;
  maxLength?: number;
  regex?: RegExp;
  initialValue?: string;
  focus?: boolean;
  validationOverride?: (input: string) => boolean;
  type?: string;
}

const CssTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderColor: '#002368'
      },
      '&.Mui-focused fieldset': {
        borderColor: '#B1E5EA'
      }
    }
  }
})(TextField);

export const GroupedTextField: React.FC<GroupedTextFieldProps> = (props: GroupedTextFieldProps) => {
  const {
    name,
    label,
    validationErrorMessage,
    required,
    groupName,
    fieldName,
    fullWidth,
    minLength,
    maxLength,
    regex,
    initialValue,
    focus,
    validationOverride,
    type
  } = props;
  const value = useRef('');
  const failedValidation = useRef(false);
  const { state, dispatch } = useContext(Context);

  const validate = (): boolean => {
    let lengthValid = true;
    if (minLength && maxLength) {
      lengthValid = value.current.length >= minLength && value.current.length <= maxLength;
    } else if (minLength) {
      lengthValid = value.current.length >= minLength;
    } else if (maxLength) {
      lengthValid = value.current.length <= maxLength;
    }
    if (validationOverride) {
      return validationOverride(value.current) && lengthValid;
    } else if (regex) {
      return regex.test(value.current) && lengthValid;
    }
    return lengthValid;
  };

  const onChange = (event: any) => {
    value.current = event.target.value;
    dispatch({ type: SetActionTypes.STRING, group: groupName, key: fieldName, stringPayload: event.target.value });
    if (value.current.length === 0 && !required) {
      failedValidation.current = false;
      dispatch({ type: SetActionTypes.BOOLEAN, group: groupName, key: `${fieldName}Error`, booleanPayload: false });
    } else {
      if (!validate()) {
        failedValidation.current = true;
        dispatch({ type: SetActionTypes.BOOLEAN, group: groupName, key: `${fieldName}Error`, booleanPayload: true });
      } else {
        failedValidation.current = false;
        dispatch({ type: SetActionTypes.BOOLEAN, group: groupName, key: `${fieldName}Error`, booleanPayload: false });
      }
    }
  };

  return (
    <React.Fragment>
      <Tooltip
        title={validationErrorMessage}
        placement="right"
        arrow
        open={failedValidation.current}
        className="validationError"
      >
        <CssTextField
          value={initialValue ? initialValue : value.current}
          name={name}
          label={label}
          variant="outlined"
          required={required}
          fullWidth={fullWidth}
          error={state[groupName]![`${fieldName}Error`]}
          onChange={onChange}
          autoFocus={focus}
          type={type ? type : 'text'}
        />
      </Tooltip>
    </React.Fragment>
  );
};

import React, { useContext, useState } from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { GroupedTextField } from '../shared/GroupedTextField';
import {
  AllRegistrationFields,
  OptionalFields,
  RegistrationDetailsRequiredFields,
  SuperAdminDetailsRequiredFields
} from '../../constants/registration';
import { ContextGroup, SetActionTypes } from '../../constants/context';
import { Context } from '../context/Store';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { emailValidator } from '../../constants/email-validation';
import { NameRegex, NameNumberRegex, NumberOnlyRequiredRegex } from '../../constants/regex';

interface RegistrationDetailsProps {
  initialFirstName?: string;
  initialLastName?: string;
  initialTitle?: string;
  initialEmail?: string;
  initialPhone?: string;
  initialAddress1?: string;
  initialAddress2?: string;
  initialCityOrTown?: string;
  initialPostalCode?: string;
  initialStateOrProvinceOrRegion?: string;
  initialCountry?: string;
  initialSuperAdmin?: boolean;
  isAffiliate: boolean;
  onSuperAdminCheckboxChange?: () => void;
}

export const RegistrationDetails: React.FC<RegistrationDetailsProps> = (props: RegistrationDetailsProps) => {
  const {
    initialFirstName,
    initialLastName,
    initialTitle,
    initialEmail,
    initialPhone,
    initialAddress1,
    initialAddress2,
    initialCityOrTown,
    initialPostalCode,
    initialStateOrProvinceOrRegion,
    initialCountry,
    initialSuperAdmin,
    isAffiliate,
    onSuperAdminCheckboxChange
  } = props;
  const { state, dispatch } = useContext(Context);
  const [checked, setChecked] = useState(
    state[ContextGroup.REGISTRATION]![SuperAdminDetailsRequiredFields.TOS_AGREED] || false
  );
  const [superAdmin, setSuperAdmin] = useState(initialSuperAdmin || false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    /*
    TOS must be checked and agreed to in order to allow registration
    State will be used to disable the button on the Register page
     */
    if (event.target.checked) {
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: SuperAdminDetailsRequiredFields.TOS_AGREED,
        booleanPayload: event.target.checked
      });
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: `${SuperAdminDetailsRequiredFields.TOS_AGREED}Error`,
        booleanPayload: false
      });
    } else {
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: SuperAdminDetailsRequiredFields.TOS_AGREED,
        booleanPayload: event.target.checked
      });
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: `${SuperAdminDetailsRequiredFields.TOS_AGREED}Error`,
        booleanPayload: true
      });
    }
  };

  const handleSuperAdminCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSuperAdmin(event.target.checked);
    if (!event.target.checked) {
      // disable the TOS agreed state in case they uncheck that they're a super admin
      handleCheckboxChange(event);
      if (onSuperAdminCheckboxChange) {
        onSuperAdminCheckboxChange();
      }
    }
    if (event.target.checked) {
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: AllRegistrationFields.SUPER_ADMIN,
        booleanPayload: event.target.checked
      });
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: `${AllRegistrationFields.SUPER_ADMIN}Error`,
        booleanPayload: false
      });
    } else {
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: AllRegistrationFields.SUPER_ADMIN,
        booleanPayload: event.target.checked
      });
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: `${AllRegistrationFields.SUPER_ADMIN}Error`,
        booleanPayload: true
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="paper-registration">
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            {!isAffiliate && (
              <Typography variant="subtitle1" className="registrationNotice">
                Thank you for your interest. To activate your registration, please complete the form below.
              </Typography>
            )}
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" justify="center" className="step">
          <Grid item xs={12}>
            <GroupedTextField
              name={RegistrationDetailsRequiredFields.FIRST_NAME}
              label="First Name"
              validationErrorMessage="Must be between 2-20 valid characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={RegistrationDetailsRequiredFields.FIRST_NAME}
              fullWidth={true}
              minLength={2}
              maxLength={20}
              regex={NameRegex}
              initialValue={initialFirstName}
              focus={true}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={RegistrationDetailsRequiredFields.LAST_NAME}
              label="Last Name"
              validationErrorMessage="Must be between 2-20 valid characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={RegistrationDetailsRequiredFields.LAST_NAME}
              fullWidth={true}
              minLength={2}
              maxLength={20}
              regex={NameRegex}
              initialValue={initialLastName}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={RegistrationDetailsRequiredFields.TITLE}
              label="Job Title"
              validationErrorMessage="Must be between 2-20 valid characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={RegistrationDetailsRequiredFields.TITLE}
              fullWidth={true}
              minLength={2}
              maxLength={20}
              regex={NameNumberRegex}
              initialValue={initialTitle}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={RegistrationDetailsRequiredFields.EMAIL}
              label="Email"
              validationErrorMessage="Please enter corporate email address"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={RegistrationDetailsRequiredFields.EMAIL}
              fullWidth={true}
              minLength={3}
              maxLength={50}
              initialValue={initialEmail}
              validationOverride={emailValidator}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={RegistrationDetailsRequiredFields.PHONE}
              label="Phone"
              validationErrorMessage="Must be digits without special characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={RegistrationDetailsRequiredFields.PHONE}
              fullWidth={true}
              regex={NumberOnlyRequiredRegex}
              initialValue={initialPhone}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={RegistrationDetailsRequiredFields.ADDRESS_LINE_1}
              label="Address Line 1"
              validationErrorMessage="Must be between 3-50 characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={RegistrationDetailsRequiredFields.ADDRESS_LINE_1}
              fullWidth={true}
              minLength={3}
              maxLength={50}
              initialValue={initialAddress1}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={AllRegistrationFields.ADDRESS_LINE_2}
              label="Address Line 2"
              validationErrorMessage="Must be between 3-50 characters"
              required={false}
              groupName={ContextGroup.REGISTRATION}
              fieldName={AllRegistrationFields.ADDRESS_LINE_2}
              fullWidth={true}
              minLength={3}
              maxLength={50}
              initialValue={initialAddress2}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={RegistrationDetailsRequiredFields.CITY_OR_TOWN}
              label="City/Town"
              validationErrorMessage="Must be between 2-50 characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={RegistrationDetailsRequiredFields.CITY_OR_TOWN}
              fullWidth={true}
              minLength={2}
              maxLength={50}
              initialValue={initialCityOrTown}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={6}>
            <GroupedTextField
              name={OptionalFields.ZIP_OR_POSTAL_CODE}
              label="Zip/Postal Code"
              validationErrorMessage="Please enter a valid zip/postal code"
              required={false}
              groupName={ContextGroup.REGISTRATION}
              fieldName={OptionalFields.ZIP_OR_POSTAL_CODE}
              fullWidth={true}
              minLength={4}
              maxLength={10}
              initialValue={initialPostalCode}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={6}>
            <GroupedTextField
              name={RegistrationDetailsRequiredFields.STATE_OR_PROVINCE_OR_REGION}
              label="State/Province/Region"
              validationErrorMessage="Must be between 2-50 characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={RegistrationDetailsRequiredFields.STATE_OR_PROVINCE_OR_REGION}
              fullWidth={true}
              minLength={2}
              maxLength={50}
              initialValue={initialStateOrProvinceOrRegion}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={RegistrationDetailsRequiredFields.COUNTRY}
              label="Country"
              validationErrorMessage="Must be between 3-50 characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={RegistrationDetailsRequiredFields.COUNTRY}
              fullWidth={true}
              minLength={3}
              maxLength={50}
              initialValue={initialCountry}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" style={{ textAlign: 'left' }}>
              <strong>Note</strong>: All organizations that register must designate a Super Administrator who will have
              administrative rights to the Mastercard ACAMS Risk Assessment account. Super Administrator should be the
              Head of AML Compliance or their designee at the financial institution.
            </Typography>
            <FormControlLabel
              style={{ textAlign: 'left', marginTop: '5%' }}
              control={<Checkbox checked={superAdmin} onChange={handleSuperAdminCheckboxChange} color="primary" />}
              label="Please check if you are the Super Administrator for your organization."
            />
          </Grid>
          {state[ContextGroup.REGISTRATION]![AllRegistrationFields.SUPER_ADMIN] && (
            <Grid item xs={12}>
              <FormControlLabel
                style={{ textAlign: 'left' }}
                control={<Checkbox checked={checked} onChange={handleCheckboxChange} color="primary" />}
                label="By activating registration, I acknowledge and agree that my information will be shared between ACAMS and Mastercard as it relates to using the Mastercard ACAMS Risk Assessment tool. I agree that access and use of the Mastercard ACAMS Risk Assessment tool is considered a Value Added Service, as defined, and subject to the Mastercard Rules and subject to the terms and conditions of the Mastercard Product Guide available on Mastercard Connect, and such Mastercard Rules and Mastercard Product Guide are incorporated herein by reference and may be updated by Mastercard from time to time."
              />
            </Grid>
          )}
        </Grid>
      </div>
    </Container>
  );
};

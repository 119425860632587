import React, { useContext, useState } from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { GroupedTextField } from '../shared/GroupedTextField';
import { DemoRegistrationFields } from '../../constants/registration';
import { ContextGroup, SetActionTypes } from '../../constants/context';
import { RouteComponentProps } from '@reach/router';
import { emailValidator } from '../../constants/email-validation';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { Context } from '../context/Store';
import { NameRegex, NameNumberRegex, NumberOnlyRegex } from '../../constants/regex';

export const DemoRegistrationForm: React.FC<RouteComponentProps> = () => {
  const [tosAgreed, setTosAgreed] = useState(false);
  const { state, dispatch } = useContext(Context);

  const handleTosChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTosAgreed(event.target.checked);
    if (event.target.checked) {
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.DEMO_REGISTRATION,
        key: DemoRegistrationFields.TOS_AGREED,
        booleanPayload: event.target.checked
      });
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.DEMO_REGISTRATION,
        key: `${DemoRegistrationFields.TOS_AGREED}Error`,
        booleanPayload: false
      });
    } else {
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.DEMO_REGISTRATION,
        key: DemoRegistrationFields.TOS_AGREED,
        booleanPayload: event.target.checked
      });
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.DEMO_REGISTRATION,
        key: `${DemoRegistrationFields.TOS_AGREED}Error`,
        booleanPayload: true
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="paper-registration">
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '2.5%' }}>
            <Typography component="h1" variant="subtitle1" className="registrationNotice">
              Want to learn more? Register today for a free 30&#8209;day access to product demo and get an inside look
              at Mastercard ACAMS Risk Assessment. Up to 5 users per Mastercard ID may register.
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={2} alignItems="center" justify="center" className="step">
          <Grid item xs={12}>
            <GroupedTextField
              name={DemoRegistrationFields.FINANCIAL_INSTITUTION}
              label="Financial Institution Name"
              validationErrorMessage="Must be between 3-50 valid characters"
              required={true}
              groupName={ContextGroup.DEMO_REGISTRATION}
              fieldName={DemoRegistrationFields.FINANCIAL_INSTITUTION}
              fullWidth={true}
              minLength={3}
              maxLength={50}
              regex={NameNumberRegex}
              focus={true}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={DemoRegistrationFields.MASTERCARD_ID}
              label="Primary/Sponsoring ICA #"
              validationErrorMessage="Invalid Primary/Sponsoring ICA #"
              required={true}
              groupName={ContextGroup.DEMO_REGISTRATION}
              fieldName={DemoRegistrationFields.MASTERCARD_ID}
              fullWidth={true}
              minLength={3}
              maxLength={20}
              regex={NumberOnlyRegex}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={DemoRegistrationFields.FIRST_NAME}
              label="First Name"
              validationErrorMessage="Must be between 2-20 valid characters"
              required={true}
              groupName={ContextGroup.DEMO_REGISTRATION}
              fieldName={DemoRegistrationFields.FIRST_NAME}
              fullWidth={true}
              minLength={2}
              maxLength={20}
              regex={NameRegex}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={DemoRegistrationFields.LAST_NAME}
              label="Last Name"
              validationErrorMessage="Must be between 2-20 valid characters"
              required={true}
              groupName={ContextGroup.DEMO_REGISTRATION}
              fieldName={DemoRegistrationFields.LAST_NAME}
              fullWidth={true}
              minLength={2}
              maxLength={20}
              regex={NameRegex}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={DemoRegistrationFields.TITLE}
              label="Job Title"
              validationErrorMessage="Must be between 2-20 valid characters"
              required={true}
              groupName={ContextGroup.DEMO_REGISTRATION}
              fieldName={DemoRegistrationFields.TITLE}
              fullWidth={true}
              minLength={2}
              maxLength={20}
              regex={NameNumberRegex}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={DemoRegistrationFields.EMAIL}
              label="Email"
              validationErrorMessage="Please enter corporate email address"
              required={true}
              groupName={ContextGroup.DEMO_REGISTRATION}
              fieldName={DemoRegistrationFields.EMAIL}
              fullWidth={true}
              minLength={3}
              maxLength={50}
              validationOverride={emailValidator}
            ></GroupedTextField>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={2}
          alignItems="center"
          justify="flex-start"
          className="step"
          style={{ textAlign: 'left' }}
        >
          <Grid item xs={12}>
            <Typography variant="subtitle1">
              By registering, you acknowledge and agree that you will be accessing confidential and proprietary
              information of the Association of Certified Anti-Money Laundering Specialists, LLC (“ACAMS”). You agree
              not to share, copy, publicly display or screen shot any of the information on this site without ACAMS
              prior written consent. Additionally, you agree to not share your login information with anyone else in
              order to access this site. You understand that this site is for demonstrative purposes only and you will
              not be able to save or create any reports within this site. ACAMS does not provide any advice or guarantee
              any results from use of this site.
            </Typography>
            <FormControlLabel
              style={{ marginTop: '5%' }}
              control={<Checkbox checked={tosAgreed} onChange={handleTosChange} color="primary" />}
              label="I agree"
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

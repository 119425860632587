import React, { ReactFragment, useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import { Footer } from './static/Footer';
import { Notification, NotificationSeverity } from './shared/Notification';
import { ContextGroup, SetActionTypes } from '../constants/context';
import Recaptcha from 'react-recaptcha';
import { Button } from '@material-ui/core';
import { DemoRegistrationFields } from '../constants/registration';
import { Context } from './context/Store';
import { DemoRegistrationForm } from './registration/DemoRegistrationForm';
import Grid from '@material-ui/core/Grid';
import { NavigationBar } from './static/NavigationBar';

interface DemoRegisterProps {
  isAffiliate: boolean;
  path: string;
}

export const DemoRegister: React.FC<DemoRegisterProps> = (props: DemoRegisterProps) => {
  const { isAffiliate } = props;
  const { state, dispatch } = useContext(Context);
  const [validated, setValidated] = useState(false);
  const [registered, setRegistered] = useState(false);
  const [hasError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState<React.ReactFragment | null>(null);
  const [notificationMessage, setNotificationMessage] = useState<React.ReactFragment | null>(null);
  const [notificationTitle, setNotificationTitle] = useState('');
  const demoRegistrationState = state[ContextGroup.DEMO_REGISTRATION];

  const handleRegister = () => {
    const payload: { [key: string]: any } = {};
    Object.values(DemoRegistrationFields).forEach((fieldName: string) => {
      const fieldValue = state[ContextGroup.DEMO_REGISTRATION]![fieldName];
      if (fieldValue && fieldValue.length > 0) {
        payload[fieldName] =
          typeof fieldValue === 'string' || fieldValue instanceof String ? fieldValue.trim() : fieldValue;
      }
    });
    payload[DemoRegistrationFields.TOS_AGREED] = true;
    dispatch({
      type: SetActionTypes.OBJECT,
      group: ContextGroup.DEMO_REGISTRATION,
      key: 'STORED_REQUEST',
      objectPayload: payload
    });
  };

  const onCaptchaSuccess = async (res: string) => {
    const storedPayload = state[ContextGroup.DEMO_REGISTRATION]!['STORED_REQUEST'];
    storedPayload.captchaUserResponseToken = res;
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_API_BASE_URL + '/demo-registrations', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(storedPayload)
      });
      const data = await response.json();
      switch (data.messageKey) {
        case 'MSG_KEY_DEMO_REGISTERED':
          setRegistered(true);
          setNotificationTitle('Thank you for registering.');
          setNotificationMessage(
            <React.Fragment>
              You will receive an email with login information for access to the demo site in the next few days pending
              approval.
              <br></br>
              <br></br>
              In the meantime, if you have any questions, please contact Customer Support at
              <a href="mailto:FinancialCrimeHelpdesk@mastercard.com">FinancialCrimeHelpdesk@mastercard.com.</a>
            </React.Fragment>
          );
          break;
        case 'MSG_KEY_DEMO_ACTIVE':
          setRegistered(true);
          setNotificationTitle('Your account to access the Mastercard ACAMS Risk Assessment demo site already exists.');
          setNotificationMessage(
            <React.Fragment>
              Explore the demo at{' '}
              <a href={process.env.REACT_APP_DEMO_ACAMS_LINK}>{process.env.REACT_APP_DEMO_ACAMS_LINK}.</a>
            </React.Fragment>
          );
          break;
        case 'MSG_KEY_DEMO_REAUTHORIZED':
          setRegistered(true);
          setNotificationTitle(
            'Thank you for extending your access to the Mastercard ACAMS Risk Assessment demo site.'
          );
          setNotificationMessage(
            <React.Fragment>
              Your demo access period has been extended for 30 days.
              <br></br>
              <br></br>
              Explore the demo at{' '}
              <a href={process.env.REACT_APP_DEMO_ACAMS_LINK}>{process.env.REACT_APP_DEMO_ACAMS_LINK}.</a>
            </React.Fragment>
          );
          break;
        case 'MSG_KEY_DEMO_EXPIRED':
          setError(true);
          setErrorMessage(
            <React.Fragment>
              Your access period has expired.
              <br></br>
              <br></br>
              If you have any questions, please contact Customer Support at
              <a href="mailto:FinancialCrimeHelpdesk@mastercard.com">FinancialCrimeHelpdesk@mastercard.com.</a>
            </React.Fragment>
          );
          break;
        case 'MSG_KEY_DEMO_MAX_REGISTRATIONS_REACHED':
          setError(true);
          setErrorMessage(
            <React.Fragment>
              We are not able to process your submission because your institution has reached its limit of 5 users
              allowed per demo access.
              <br></br>
              <br></br>
              If you have any questions, please contact Customer Support at
              <a href="mailto:FinancialCrimeHelpdesk@mastercard.com">FinancialCrimeHelpdesk@mastercard.com.</a>
            </React.Fragment>
          );
          break;
        default:
          throw response;
      }
    } catch (error) {
      setError(true);
      setErrorMessage(<React.Fragment>{error.statusText}</React.Fragment>);
    }
  };

  useEffect(() => {
    let validated = true;
    // Make sure all required fields are present
    Object.values(DemoRegistrationFields).forEach(fieldName => {
      if (!state[ContextGroup.DEMO_REGISTRATION]![fieldName]) {
        validated = false;
      }
      // Make sure present fields don't contain errors
      if (state[ContextGroup.DEMO_REGISTRATION]![`${fieldName}Error`]) {
        validated = false;
      }
    });
    setValidated(validated);
  }, [demoRegistrationState, state]);

  return (
    <React.Fragment>
      <Container component="main" maxWidth="xl" className="registration-container">
        <NavigationBar isAffiliate={isAffiliate}></NavigationBar>
        {/* IE needs to have marginBottom specified on the grid, but other browsers accept it for registration-container */}
        <Grid container justify="center" style={{ marginBottom: '3%' }}>
          <Grid item xs={8} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <div>
              <Notification
                show={registered}
                notificationTitle={notificationTitle}
                message={notificationMessage as ReactFragment}
                severity={NotificationSeverity.SUCCESS}
              />
              <Notification
                show={hasError}
                notificationTitle="Registration Error"
                message={errorMessage as ReactFragment}
                severity={NotificationSeverity.ERROR}
              />
            </div>
          </Grid>
          {/* Don't show this if registered successfully */}
          {!registered && (
            <Grid item xs={12} sm={12} md={6} lg={5} direction="column" style={{ display: 'flex' }}>
              <DemoRegistrationForm></DemoRegistrationForm>
              <Container maxWidth="xs">
                {state[ContextGroup.DEMO_REGISTRATION]!['STORED_REQUEST'] &&
                  state[ContextGroup.DEMO_REGISTRATION]![DemoRegistrationFields.TOS_AGREED] &&
                  validated && (
                    <Recaptcha
                      sitekey={process.env.REACT_APP_GOOGLE_CAPTCHA_SITE_KEY}
                      render="explicit"
                      verifyCallback={onCaptchaSuccess}
                    />
                  )}
                <Grid container justify="center">
                  <Grid item xs={12}>
                    <Button
                      className="full-width-button"
                      variant="contained"
                      color="primary"
                      onClick={handleRegister}
                      // Disable the button if not all required fields are filled in
                      disabled={
                        !validated || !state[ContextGroup.DEMO_REGISTRATION]![DemoRegistrationFields.TOS_AGREED]
                      }
                    >
                      Register
                    </Button>
                  </Grid>
                </Grid>
              </Container>
            </Grid>
          )}
        </Grid>
      </Container>
      <div className="footer-box">
        <Footer isAffiliate={isAffiliate}></Footer>
      </div>
    </React.Fragment>
  );
};

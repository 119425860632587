import React, { useContext, useEffect, useState } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { GroupedTextField } from '../shared/GroupedTextField';
import { CsvUploadCredentialsFields, DemoRegistrationFields, PasswordFields } from '../../constants/registration';
import { ContextGroup } from '../../constants/context';
import { RouteComponentProps } from '@reach/router';
import * as QueryString from 'query-string';
import { NavigationBar } from '../static/NavigationBar';
import { Context } from '../context/Store';
import { Button, Link } from '@material-ui/core';
import { Notification, NotificationSeverity } from '../shared/Notification';

export const PasswordForm: React.FC<RouteComponentProps> = props => {
  const { location } = props;
  const { email, code } = QueryString.parse(location!.search);

  const { state } = useContext(Context);

  const [validated, setValidated] = useState(false);
  const [passwordCodeValid, setPasswordCodeIsValid] = useState(false);
  const [hasError, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordSaved, setPasswordSaved] = useState(false);

  // Validates the reset code/email anytime they changed in the URL
  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_API_BASE_URL}/users/reset-password?email=${email}&code=${code}`,
        {
          headers: { 'Content-Type': 'application/json' }
        }
      );
      if (response.ok) {
        setPasswordCodeIsValid(true);
      }
    }

    fetchData();
  }, [code, email]);

  const isValid = () => {
    let validated = true;
    // Make sure all required fields are present
    Object.values(PasswordFields).forEach(fieldName => {
      if (!state[ContextGroup.PASSWORD_SET]![fieldName]) {
        validated = false;
      }
      // Make sure present fields don't contain errors
      if (state[ContextGroup.PASSWORD_SET]![`${fieldName}Error`]) {
        validated = false;
      }
    });

    if (
      state[ContextGroup.PASSWORD_SET]![PasswordFields.PASSWORD] !==
      state[ContextGroup.PASSWORD_SET]![PasswordFields.PASSWORD_CONFIRMATION]
    ) {
      validated = false;
    }

    return validated;
  };

  useEffect(() => {
    setValidated(isValid());
  }, [state[ContextGroup.PASSWORD_SET]]);

  const passwordValidator = (input: string): boolean => {
    return state[ContextGroup.PASSWORD_SET]![PasswordFields.PASSWORD] === input;
  };

  const setPassword = async () => {
    const payload: { emailAddress: string; passwordResetCode: string; password: string } = {
      password: state[ContextGroup.PASSWORD_SET]![PasswordFields.PASSWORD],
      passwordResetCode: code as string,
      emailAddress: email as string
    };
    try {
      const response = await fetch(process.env.REACT_APP_BACKEND_API_BASE_URL + '/users/reset-password', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(payload)
      });
      if (!response.ok) {
        // fetch does not fail promises unless there's a connection error
        throw Error(response.statusText);
      }
      setPasswordSaved(true);
    } catch (error) {
      setError(true);
      setErrorMessage(error.message);
    }
  };

  return (
    <React.Fragment>
      <NavigationBar isAffiliate={false}></NavigationBar>

      {passwordCodeValid && !passwordSaved && (
        <Container component="main" maxWidth="md" className="registration-container">
          <Grid spacing={3}>
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', marginTop: '2.5%' }}>
              <Typography variant="h5">
                Welcome to the Mastercard ACAMS Demo Site. Please set a password to continue.
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <p>Your password must meet the following criteria:</p>
              <ul style={{ textAlign: 'left' }}>
                <li>Must contain more than 12 and less than 128 characters</li>
                <li>
                  Include at least 2 of the following types of characters: upper case letters (A-Z), lowercase letters
                  (a-z), numeric digits (0-9), and symbols (!@#$%^*&()_+)
                </li>
              </ul>
            </Grid>
          </Grid>
          <Grid container justify="center" spacing={3}>
            <Grid item xs={8} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
              <div>
                <Notification
                  show={hasError}
                  notificationTitle="Password Error"
                  message={errorMessage}
                  severity={NotificationSeverity.ERROR}
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={6} direction="column" style={{ display: 'flex' }}>
              <GroupedTextField
                name={PasswordFields.PASSWORD}
                type="password"
                label="Password"
                validationErrorMessage="Must be between 12-128 characters and include at least 2 of the following: upper case letters, lowercase letters, digits, and symbols (!@#$%^*&()_+)"
                required={true}
                groupName={ContextGroup.PASSWORD_SET}
                fieldName={PasswordFields.PASSWORD}
                fullWidth={true}
                minLength={12}
                maxLength={128}
                regex={
                  /(?=^.{12,128}$)((?=.*\d)(?=.*[A-Z])|(?=.*\d)(?=.*[a-z])|(?=.*\d)(?=.*[!@#$%^*&()_+])|(?=.*[A-Z])(?=.*[a-z])|(?=.*[A-Z])(?=.*[!@#$%^*&()_+])|(?=.*[a-z])(?=.*[!@#$%^*&()_+]))^.*/m
                }
                focus={true}
              ></GroupedTextField>
            </Grid>
            <Grid item xs={12} sm={12} md={6} direction="column" style={{ display: 'flex' }}>
              <GroupedTextField
                name={PasswordFields.PASSWORD_CONFIRMATION}
                type="password"
                label="Password Confirmation"
                validationErrorMessage="Passwords must match"
                required={true}
                groupName={ContextGroup.PASSWORD_SET}
                fieldName={PasswordFields.PASSWORD_CONFIRMATION}
                validationOverride={passwordValidator}
                fullWidth={true}
              ></GroupedTextField>
            </Grid>
            <Grid item xs={12} sm={12} md={12} direction="column" style={{ display: 'flex' }}>
              <Button
                className="full-width-button"
                variant="contained"
                color="primary"
                onClick={setPassword}
                // Disable the button if not all required fields are filled in
                disabled={!validated}
              >
                Save Password
              </Button>
            </Grid>
          </Grid>
        </Container>
      )}
      {!passwordCodeValid && (
        <Grid container justify="center" spacing={3}>
          <Grid item xs={8} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <div>
              <Notification
                show={true}
                notificationTitle="Invalid or expired password reset code"
                message={
                  <React.Fragment>
                    You have either reached this page in error or your password reset code has expired.
                    <br></br>
                    <br></br>
                    If you have any questions, please contact Customer Support at
                    <a href="mailto:FinancialCrimeHelpdesk@mastercard.com">FinancialCrimeHelpdesk@mastercard.com.</a>
                  </React.Fragment>
                }
                severity={NotificationSeverity.WARNING}
              />
            </div>
          </Grid>
        </Grid>
      )}
      {passwordCodeValid && passwordSaved && (
        <Grid container justify="center" spacing={3}>
          <Grid item xs={8} style={{ display: 'flex', textAlign: 'center', justifyContent: 'center' }}>
            <div>
              <Notification
                show={true}
                notificationTitle="Your password has been successfully set."
                message={
                  <React.Fragment>
                    <br />
                    Please take note of your ID:
                    <strong>{email as string}</strong>
                    <br />
                    <br />
                    Explore the demo at:{' '}
                    <Link href={process.env.REACT_APP_DEMO_ACAMS_LINK}>{process.env.REACT_APP_DEMO_ACAMS_LINK}</Link>
                    <br />
                    <br />
                    If you have any questions, please contact Customer Support at
                    <a href="mailto:FinancialCrimeHelpdesk@mastercard.com">FinancialCrimeHelpdesk@mastercard.com.</a>
                  </React.Fragment>
                }
                severity={NotificationSeverity.SUCCESS}
              />
            </div>
          </Grid>
        </Grid>
      )}
    </React.Fragment>
  );
};

import React, { useContext, useState } from 'react';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { GroupedTextField } from '../shared/GroupedTextField';
import { SuperAdminDetailsRequiredFields } from '../../constants/registration';
import { ContextGroup, SetActionTypes } from '../../constants/context';
import { Context } from '../context/Store';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { emailValidator } from '../../constants/email-validation';
import { NameRegex, NameNumberRegex } from '../../constants/regex';

interface SuperAdminDetailsProps {
  initialFirstName?: string;
  initialLastName?: string;
  initialTitle?: string;
  initialEmail?: string;
}

export const SuperAdminDetails: React.FC<SuperAdminDetailsProps> = (props: SuperAdminDetailsProps) => {
  const { initialFirstName, initialLastName, initialTitle, initialEmail } = props;
  const { dispatch } = useContext(Context);
  const [checked, setChecked] = useState(false);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    /*
    TOS must be checked and agreed to in order to allow registration
    State will be used to disable the button on the Register page
     */
    if (event.target.checked) {
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: SuperAdminDetailsRequiredFields.TOS_AGREED,
        booleanPayload: event.target.checked
      });
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: `${SuperAdminDetailsRequiredFields.TOS_AGREED}Error`,
        booleanPayload: false
      });
    } else {
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: SuperAdminDetailsRequiredFields.TOS_AGREED,
        booleanPayload: event.target.checked
      });
      dispatch({
        type: SetActionTypes.BOOLEAN,
        group: ContextGroup.REGISTRATION,
        key: `${SuperAdminDetailsRequiredFields.TOS_AGREED}Error`,
        booleanPayload: true
      });
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <div className="paper-registration">
        <Grid container justify="center" spacing={3}>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography component="h1" variant="h5" className="registrationTitle">
              Super Administrator Details
            </Typography>
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <Typography variant="subtitle1" className="registrationNotice">
              Super Administrator should be the Head of AML Compliance or their designee, as he or she will have
              administrative rights to the Mastercard ACAMS Risk Assessment account.
            </Typography>
          </Grid>
        </Grid>
        <Grid container spacing={2} alignItems="center" justify="center" className="step">
          <Grid item xs={12}>
            <GroupedTextField
              name={SuperAdminDetailsRequiredFields.SUPER_ADMIN_FIRST_NAME}
              label="First Name"
              validationErrorMessage="Must be between 2-20 valid characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={SuperAdminDetailsRequiredFields.SUPER_ADMIN_FIRST_NAME}
              fullWidth={true}
              minLength={2}
              maxLength={20}
              regex={NameRegex}
              initialValue={initialFirstName}
              focus={true}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={SuperAdminDetailsRequiredFields.SUPER_ADMIN_LAST_NAME}
              label="Last Name"
              validationErrorMessage="Must be between 2-20 valid characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={SuperAdminDetailsRequiredFields.SUPER_ADMIN_LAST_NAME}
              fullWidth={true}
              minLength={2}
              maxLength={20}
              regex={NameRegex}
              initialValue={initialLastName}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={SuperAdminDetailsRequiredFields.SUPER_ADMIN_TITLE}
              label="Job Title"
              validationErrorMessage="Must be between 2-20 valid characters"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={SuperAdminDetailsRequiredFields.SUPER_ADMIN_TITLE}
              fullWidth={true}
              minLength={2}
              maxLength={20}
              regex={NameNumberRegex}
              initialValue={initialTitle}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <GroupedTextField
              name={SuperAdminDetailsRequiredFields.SUPER_ADMIN_EMAIL}
              label="Email"
              validationErrorMessage="Please enter corporate email address"
              required={true}
              groupName={ContextGroup.REGISTRATION}
              fieldName={SuperAdminDetailsRequiredFields.SUPER_ADMIN_EMAIL}
              fullWidth={true}
              minLength={3}
              maxLength={50}
              initialValue={initialEmail}
              validationOverride={emailValidator}
            ></GroupedTextField>
          </Grid>
          <Grid item xs={12}>
            <FormControlLabel
              style={{ textAlign: 'left' }}
              control={<Checkbox checked={checked} onChange={handleCheckboxChange} color="primary" />}
              label="By activating registration, I acknowledge and agree that my information will be shared between ACAMS and Mastercard as it relates to using the Mastercard ACAMS Risk Assessment tool. I agree that access and use of the Mastercard ACAMS Risk Assessment tool is considered a Value Added Service, as defined, and subject to the Mastercard Rules and subject to the terms and conditions of the Mastercard Product Guide available on Mastercard Connect, and such Mastercard Rules and Mastercard Product Guide are incorporated herein by reference and may be updated by Mastercard from time to time."
            />
          </Grid>
        </Grid>
      </div>
    </Container>
  );
};

import { ContextGroup, SetActionTypes } from '../../constants/context';
import React, { useContext, useRef } from 'react';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@material-ui/core';
import { Context } from '../context/Store';

export interface RadioButtonOption {
  label: string;
  value: string;
}

export type RadioButtons = RadioButtonOption[];

interface Props {
  name: string;
  label: string;
  validationErrorMessage?: string;
  groupName: ContextGroup; // this will be used to logically group inputs together in the context/state
  fieldName: string;
  initialValue?: string;
  buttonOptions: RadioButtons;
}

export const GroupedRadioButton: React.FC<Props> = (props: Props) => {
  const { name, label, groupName, fieldName, initialValue = '', buttonOptions } = props;
  const { state, dispatch } = useContext(Context);
  const value = useRef('');

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // This will always clear the error because an option will then be selected
    value.current = event.target.value;
    dispatch({
      type: SetActionTypes.STRING,
      group: groupName,
      key: fieldName,
      stringPayload: (event.target as HTMLInputElement).value
    });
    dispatch({
      type: SetActionTypes.BOOLEAN,
      group: groupName,
      key: `${fieldName}Error`,
      booleanPayload: false
    });
  };

  return (
    <FormControl component="fieldset">
      <RadioGroup
        aria-label={label}
        name={name}
        value={value.current.length > 0 ? value.current : initialValue}
        onChange={handleRadioChange}
        className={state[ContextGroup.REGISTRATION]![`${fieldName}Error`] ? 'radioError' : 'radioSelection'}
      >
        {buttonOptions.map(option => {
          return (
            <FormControlLabel
              control={<Radio />}
              label={option.label}
              value={option.value}
              key={option.value + option.label}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
};

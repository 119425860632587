import React, { ReactFragment, useEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import Collapse from '@material-ui/core/Collapse';

export enum NotificationSeverity {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info'
}

interface Props {
  show: boolean;
  message: ReactFragment | string;
  severity: NotificationSeverity;
  notificationTitle: string;
}

export const Notification: React.FC<Props> = (props: Props) => {
  const { show, message, severity, notificationTitle } = props;
  const [open, setOpen] = useState(show);
  const [notificationMessage, setNotificationMessage] = useState(message);

  /*
  useState will only set open to show during the first render (if the prop changes during re-renders, it won't update)
  useEffect will update the state whenever the prop changes (during subsequent re-renders)
   */
  useEffect(() => {
    setOpen(show);
  }, [show]);

  useEffect(() => {
    setNotificationMessage(message);
  }, [message]);

  // When in hidden state, apply the notificationHidden class (material ui class override for Collapse
  return (
    <div className="notify">
      <Collapse in={open} className="notification" classes={{ hidden: 'notificationHidden' }}>
        <Alert severity={severity}>
          <strong>{notificationTitle}</strong>
          <br></br>
          {notificationMessage}
        </Alert>
      </Collapse>
    </div>
  );
};

import React, { useEffect } from 'react';
import { NavigationBar } from './NavigationBar';
import Typography from '@material-ui/core/Typography';
import { Footer } from './Footer';
import leftPopup from '../../assets/benefits-left-detail.gif';
import rightPopup from '../../assets/overall-risk-popup.png';
import leftPopupIcon from '../../assets/benefits-left-thumbnail.gif';
import rightPopupIcon from '../../assets/overall-risk-thumbnail.png';
import Modal from '@material-ui/core/Modal';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import { Card } from '@material-ui/core';
import CardContent from '@material-ui/core/CardContent';

const modalStyle = {
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  position: 'absolute'
} as React.CSSProperties;

interface BenefitsProps {
  isAffiliate: boolean;
  path: string;
}

export const Benefits: React.FC<BenefitsProps> = (props: BenefitsProps) => {
  const { isAffiliate } = props;
  const [open, setOpen] = React.useState(false);
  const [selectedImage, setSelectedImage] = React.useState<string>();
  useEffect(() => {
    document.title = 'Benefits | Mastercard ACAMS Risk Assessment';
  }, []);

  const openLeftImage = () => {
    setSelectedImage(leftPopup);
    setOpen(true);
  };

  const openRightImage = () => {
    setSelectedImage(rightPopup);
    setOpen(true);
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <React.Fragment>
      <Modal open={open} onClose={close}>
        <img style={modalStyle} src={selectedImage} className="enlarged-image" alt="selected" />
      </Modal>
      <NavigationBar isAffiliate={isAffiliate}></NavigationBar>
      <div className="landing-banner">
        <div className="top-section-box">
          <div className="top-section">
            <Typography className="mara-subtitle" variant="h4" gutterBottom={true} display="block">
              Meeting today's regulatory challenges<br></br>
              through Best-In-Class technology
            </Typography>
          </div>
        </div>
        <div className="talk-product-box">
          <Typography className="talk-product-title" variant="h4" gutterBottom={true} display="block">
            Build Powerful Risk Assessments
          </Typography>
          <Container fixed>
            <Grid container justify="center" spacing={8} className="card-container">
              <Grid item xs={12} sm={12} md={6} lg={5} style={{ display: 'flex', marginRight: '1.5%' }}>
                <Card variant="outlined" className="benefits-card" id="left-benefits-card" onClick={openLeftImage}>
                  <CardContent>
                    <img src={leftPopupIcon} className="benefits-card-icon" alt="left-popup-icon" />
                    <Typography variant="body2" gutterBottom={true} display="block">
                      Mastercard ACAMS Risk Assessment delivers a comprehensive and automated risk-based profile of your
                      institution’s Mastercard portfolio and corresponding AML controls, offering the flexibility to assess
                      areas of highest risk, including sanctions and crypto risk, and to remediate where necessary
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={5} style={{ display: 'flex', marginLeft: '1.5%' }}>
                <Card variant="outlined" className="benefits-card" id="right-benefits-card" onClick={openRightImage}>
                  <CardContent>
                    <img src={rightPopupIcon} className="benefits-card-icon" alt="right-popup-icon" />
                    <Typography variant="body2" gutterBottom={true} display="block" className="card-description">
                      Presentation-ready reports clearly communicate your institution's risk, from comprehensive reports
                      to high-level summaries, and dynamic graphic features such as tables, pie charts, and more
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </div>
        <Container maxWidth={false} className="small-card-container-box">
          <Container fixed>
            <Grid container justify="center" spacing={4} className="small-card-container">
              <Grid item xs={12} sm={12} md={6} lg={3} style={{ display: 'flex', marginRight: '1.75%' }}>
                <Card variant="outlined" className="small-benefits-card">
                  <CardContent>
                    <Typography className="mara-card-subtitle" variant="h6" gutterBottom={true} display="block">
                      Confidence In Meeting Global Obligations
                    </Typography>
                    <Typography variant="body2" gutterBottom={true} display="block">
                      By introducing a standardized, objective and globally-relevant program, Mastercard fosters
                      compliance by design and assists customers in developing “risk assessment processes designed to
                      identify and apply appropriate risk management controls,” as required by{' '}
                      <a href={process.env.REACT_APP_ML_RULES_LINK}>Mastercard’s Global Anti-Money Laundering rules.</a>
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={6}
                lg={3}
                style={{ display: 'flex', marginLeft: '1.75%', marginRight: '1.75%' }}
              >
                <Card variant="outlined" className="small-benefits-card">
                  <CardContent>
                    <Typography className="mara-card-subtitle" variant="h6" gutterBottom={true} display="block">
                      An Objective Standardized Solution
                    </Typography>
                    <Typography variant="body2" gutterBottom={true} display="block">
                      Based on a methodology designed by public and private sector AML experts, Mastercard ACAMS Risk
                      Assessment affords Mastercard clients a verifiable, standardized methodology that responds to the
                      guidance and regulatory requirements of financial institution supervisors and regulators
                      worldwide.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={3} style={{ display: 'flex', marginLeft: '1.75%' }}>
                <Card variant="outlined" className="small-benefits-card">
                  <CardContent>
                    <Typography className="mara-card-subtitle" variant="h6" gutterBottom={true} display="block">
                      AML Program Efficiency & Effectiveness through Automation
                    </Typography>
                    <Typography variant="body2" gutterBottom={true} display="block">
                      Scoring automation for both inherent and residual risk, reduces cumbersome manual processes—
                      giving you the time to focus on what matters most: measuring, understanding and mitigating your
                      institution’s money laundering risk.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Container>
        </Container>
        <div className="team-box">
          <div className="top-section">
            <Typography className="mara-subtitle" variant="h4" gutterBottom={true} display="block">
              Be Part of a Team
            </Typography>
            <Typography variant="body2" gutterBottom={true} display="block">
              Join the ACAMS community with free new memberships for Mastercard ACAMS Risk Assessment
              customers. This benefit allows for new ACAMS memberships for up to five (5) members of your team.
              Enjoy the latest news from the AML industry, access to forums, special pricing on ACAMS trainings,
              conferences, and webinars, and more.
            </Typography>
          </div>
        </div>
        <Footer isAffiliate={isAffiliate}></Footer>
      </div>
    </React.Fragment>
  );
};

import React from 'react';
import { Router } from '@reach/router';
import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';
import { StylesProvider, ThemeProvider } from '@material-ui/core/styles';
import './css/App.scss';
import Store from './components/context/Store';
import { Register } from './components/Register';
import { DemoRegister } from './components/DemoRegister';
import { Landing } from './components/static/Landing';
import { Benefits } from './components/static/Benefits';
import { Resources } from './components/static/Resources';
import { Training } from './components/static/Training';
import { AuthorizationCsvUpload } from './components/AuthorizationCsvUpload';
import { PasswordForm } from './components/registration/PasswordForm';

const App: React.FC = () => {
  let theme = createMuiTheme({
    typography: {
      fontFamily: [
        'SharpSans-Bold',
        'SharpSans-Book',
        'SharpSans-Medium',
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"'
      ].join(',')
    }
  });
  theme = responsiveFontSizes(theme);

  return (
    <Store>
      <div className="App">
        <StylesProvider injectFirst={true}>
          <ThemeProvider theme={theme}>
            <Router basepath={process.env.PUBLIC_URL}>
              <Landing path="/" />
              <Landing path="/affiliate" />
              <Benefits isAffiliate={false} path="/benefits" />
              <Resources isAffiliate={false} path="/resources" />
              <Training isAffiliate={false} path="/training" />
              <Register isAffiliate={false} path="/register" />
              <Benefits isAffiliate={true} path="/affiliateBenefits" />
              <Resources isAffiliate={true} path="/affiliateResources" />
              <Register isAffiliate={true} path="/affiliateRegister" />
              <DemoRegister isAffiliate={false} path="/demoRegister" />
              <AuthorizationCsvUpload path="/admin/authorize-demo-registrations" />
              <PasswordForm path="/updatePassword" />
            </Router>
          </ThemeProvider>
        </StylesProvider>
      </div>
    </Store>
  );
};

export default App;
